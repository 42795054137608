import { Breadcrumbs } from "../../../AbstractElements";
import Sidebar from "../JobCardView/Sidebar";
import ListCard from "./ListCard";
import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";

const ListView = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="View Jobs" parent="Jobs" title="View Jobs" />
			<Container fluid={true}>
				<Row>
					<ListCard colClass="col-xl-12 xl-100 box-col-12" />
				</Row>
			</Container>
		</Fragment>
	);
};

export default ListView;
