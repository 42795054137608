import React, { useState } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	FormGroup,
	Input,
	Form,
} from "reactstrap";
import { FaRegTrashAlt, FaRegSave } from "react-icons/fa";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";

const ModalBookmark = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const bookmarkId = props.bookmarkId;

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The mail was successfully sent", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error sending the mail", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	let unBookmarkCandidate = async () => {
		let userId = sessionStorage.getItem("token");

		try {
			let res = await axios.delete(`${API_BASE_URL}/Unbookmark/${bookmarkId}`);
			window.location.href = "/Bookmarked-Candidates";
		} catch (err) {
			alert(err);
		}
	};

	return (
		<i
			className="candidate-icons"
			title="Un-bookmark Candidate"
			onClick={toggle}
		>
			<FaRegSave />
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader>Are you sure you want to un-bookmark?</ModalHeader>
				<ModalFooter>
					<Button id="cancel-mail" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={unBookmarkCandidate}>
						Un-bookmark
					</Button>
				</ModalFooter>
			</Modal>
		</i>
	);
};

export default ModalBookmark;
