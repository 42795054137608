import React, { Fragment, useState, useEffect } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import {
	EmailAddress,
	ForgotPassword,
	Password,
	RememberPassword,
	SignIn,
} from "../../../Constant";
import { useNavigate } from "react-router-dom";
import { firebase_app, Jwt_token } from "../../../Config/Config";
import { loginProvider } from "../../../Config/Config";
import man from "../../../assets/images/dashboard/1.png";
import { handleResponse } from "../../../Services/fack.backend";
import SocialAuth from "./SocialAuth";
import axios, { AxiosError } from "axios";
import { TopCenter, directiontoaster } from "../../../Constant";
import { toast, ToastContainer } from "react-toastify";
import "../styles.css";

const LoginTab = ({ selected }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [togglePassword, setTogglePassword] = useState(false);
	const history = useNavigate();
	const [error, setError] = useState("");
	const [roleId, setRoleId] = useState(null);
	const [recruitContactId, setRecruitContactId] = useState(null);

	const [value, setValue] = useState(
		sessionStorage.getItem("profileURL" || man)
	);
	const [name, setName] = useState(sessionStorage.getItem("Name"));
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The position was successfully added", {
					position: toast.POSITION.TOP_CENTER,

					autoClose: 1000,
				});

				break;

			case "directionsdangerToast":
				toast.error(
					"No company or Recruit Contact exists with the given email address.",
					{
						position: toast.POSITION.TOP_CENTER,

						autoClose: 1000,
					}
				);

				break;
		}
	};

	const logUserIn = async (email) => {
		try {
		  const companyResult = await axios.get(
			`${API_BASE_URL}/Company/GetByEmail?email=${email}`
		  );
	  
		  const companyID = companyResult.data.companyID;
		  const companyName = companyResult.data.name;
		  const companyAddress = companyResult.data.address;
		  const companyTelephone = companyResult.data.telephone;
	  
		  const roleIdResult = await axios.get(
			`${API_BASE_URL}/Company/GetRecruiterRoleId?email=${email}`
		  );
	  
		  if (roleIdResult.data && roleIdResult.data.includes(4)) {
			// Check if roleId 4 exists in the roleIdResult data
			setRoleId(4);
			sessionStorage.setItem("roleId", 4);
		  } else {
			setRoleId(null);
			sessionStorage.removeItem("roleId"); // Remove roleId if not 4
		  }
	  
		  const recruitContactIdResult = await axios.get(
			`${API_BASE_URL}/Company/GetRecruiterContactId?email=${email}`
		  );
	  
		  sessionStorage.setItem("companyID", companyID);
		  sessionStorage.setItem("name", companyName);
		  sessionStorage.setItem("address", companyAddress);
		  sessionStorage.setItem("telephone", companyTelephone);
		  sessionStorage.setItem("email", email);
	  
		  setTimeout(() => {
			history(`/Edit-Profile`);
		  }, 200);
		} catch (err) {
		  return directiontoaster("directionsdangerToast");
		} finally {
		  setLoading(false);
		}
	  };
	  
	useEffect(() => {
		sessionStorage.setItem("profileURL", value);
		sessionStorage.setItem("Name", name);
	}, [value, name]);

	window.onload = () => {
		sessionStorage.removeItem("token");
	};

	let handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			let res = await axios.post(
				`${API_BASE_URL}/Account/RecruitLogin`,
				{
					email: email,
					password: password,
				},
				{ headers: { "Content-Type": "application/json" } }
			);
			const userId = res.data.userId;
			sessionStorage.setItem("userId", userId);
			if (res.status === 200) {
				loginProvider.signIn(email, password);
				setEmail("");
				setPassword("");
				sessionStorage.setItem("email", email);
				sessionStorage.setItem("token", res.data.token);
				sessionStorage.setItem("name", res.data.name);
				logUserIn(email);
			}
		} catch (err) {
			let errorMessge = "Error Occurred While Logging In.";
			errorMessge = err?.response?.data?.message ?? errorMessge;
			toast.error(errorMessge, { position: toast.POSITION.TOP_CENTER });
			if (
				err?.response?.status === 404 &&
				err?.response?.data?.message ===
					"No company exists with the given email address."
			) {
				toast.error("No company exists with the given email address.", {
					position: toast.POSITION.TOP_CENTER,
				});
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Fragment>
			<Form className="theme-form" onSubmit={handleSubmit}>
				<ToastContainer />
				<H4>Sign In</H4>
				<P>{"Enter your username & password to login"}</P>
				<FormGroup>
					<Label className="col-form-label">Email</Label>
					<Input
						className="form-control"
						type="text"
						required={true}
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						autoComplete="email"
					/>
				</FormGroup>
				<FormGroup className="position-relative">
					<Label className="col-form-label">{Password}</Label>
					<Input
						className="form-control"
						type={togglePassword ? "text" : "password"}
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						required={true}
						autoComplete="current-password"
					/>
					<div
						className="show-hide"
						onClick={() => setTogglePassword(!togglePassword)}
					>
						<span className={togglePassword ? "" : "show"}></span>
					</div>
				</FormGroup>
				<div className="form-group mb-0">
					<Btn
						attrBtn={{
							color: "primary",
							className: "btn-block",
							disabled: loading ? loading : loading,
						}}
					>
						{loading ? "LOADING..." : SignIn}
					</Btn>
				</div>
				<SocialAuth />
			</Form>
		</Fragment>
	);
};
export default LoginTab;
