import { H6, Image, P, UL, Breadcrumbs } from "../../../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Row, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import "./styles.css";

const BulCardsClass = () => {
	const [data, setDate] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	useEffect(() => {
		let isMounted = true;
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/BulletinPosts`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				if (isMounted) {
					setDate(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<Fragment>
			<Breadcrumbs
				parent="Bulletin"
				title="View Bulletins"
				mainTitle="View Bulletins"
			/>
			<Container fluid={true}>
				<Col xl="12" className="box-col-12 xl-100">
					<Row>
						{data.map((data, index) => (
							<Col xl="12" className="xl-100 box-col-12" key={index}>
								<Card id="bulletin-card">
									<div className="job-search">
										<CardBody>
											<div className="media">
												<Image
													attrImage={{
														className: "img-40 img-fluid m-r-20",
														src: `${require(`../../../../assets/images/job-search/3.jpg`)}`,
														alt: "",
													}}
												/>
												<div className="media-body">
													<H6 attrH6={{ className: "f-w-600" }}>
														<Link to={`${data.bulletinMessageId}`}>
															{data.messageHeader}
														</Link>
													</H6>
													<P>{new Date(data.createDate).toDateString()}</P>

													<UL attrUL={{ className: "rating simple-list" }}>
														{data.ratingElems}
													</UL>
												</div>
											</div>
											<P className="p-text">
												{data.messageBody.length < 500
													? data.messageBody
													: data.messageBody.substring(0, 500)}
											</P>
										</CardBody>
									</div>
								</Card>
							</Col>
						))}
					</Row>
				</Col>
			</Container>
		</Fragment>
	);
};

export default BulCardsClass;
