import React, { Fragment, useEffect, useState } from "react";
import { Settings } from "react-feather";
import { Link } from "react-router-dom";
import { H6, Image, LI, UL, P } from "../../AbstractElements";
import man from "../../assets/images/dashboard/1.png";
import "./styles.css";
import axios from "axios";

const Profile = () => {
	const companyID = sessionStorage.getItem("companyID");
	const applicationUserId = sessionStorage.getItem("applicationUserId");
	let isProfilePicUploaded = false;
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const [user, setUser] = useState({
		name: "",
		companyName: "",
		address: "",
		telephone: "",
		website: "",
	});
	const [roleId, setRoleId] = useState(null);
	const [loading, setLoading] = useState(true);
	const [initialLoading, setInitialLoading] = useState(true);
	const [recruitContactId, setRecruitContactId] = useState(null);
	const loadUser = async () => {
		const email = sessionStorage.getItem("email");

		const recruitResult = await axios.get(
			`${API_BASE_URL}/Company/GetRecruitContactByEmail?email=${email}`
		);

		if (recruitResult.data.name) {
			setUser((prevUser) => ({
				...prevUser,
				name: `${recruitResult.data.name} ${recruitResult.data.surname}`,
			}));
		}

		const companyResult = await axios.get(
			`${API_BASE_URL}/Company/GetByEmail?email=${email}`
		);

		if (companyResult.data.name) {
			setUser((prevUser) => ({
				...prevUser,
				companyName: companyResult.data.name,
			}));
		}

		const roleIdResult = await axios.get(
			`${API_BASE_URL}/Company/GetRecruiterRoleId?email=${email}`
		  );
	  
		  if (roleIdResult.data) {
			if (roleIdResult.data.includes(4)) {
			  // Check if roleId 4 exists in the roleIdResult data
			  setRoleId(4);
			  sessionStorage.setItem("roleId", 4); // Update roleId in sessionStorage
			} else if (roleIdResult.data.includes(3)) {
			  // Check if roleId 3 exists in the roleIdResult data
			  setRoleId(3);
			  sessionStorage.setItem("roleId", 3); // Update roleId in sessionStorage
			} else {
			  setRoleId(null);
			  sessionStorage.removeItem("roleId"); // Remove roleId from sessionStorage if not available
			}
		  }
		const recruitContactIdResult = await axios.get(
			`${API_BASE_URL}/Company/GetRecruiterContactId?email=${email}`
		);

		if (recruitContactIdResult.data) {
			setRecruitContactId(roleIdResult.data);
			sessionStorage.setItem("recruitContactId", recruitContactIdResult.data); // Update roleId in sessionStorage
		} else {
			setRecruitContactId(null);
			sessionStorage.removeItem("recruitContactId"); // Remove roleId from sessionStorage if not available
		}

		setLoading(false); // Set loading to false after loading the user data
	};

	useEffect(() => {
		loadUser();
	}, []);

	useEffect(() => {
		if (initialLoading) {
			setInitialLoading(false);
		}
	}, []);

	const { name, companyName, currentJobTitle } = user;

	return (
		<Fragment>
			{/* Display loader if still loading */}
			{initialLoading || loading ? (
				<div>Loading...</div>
			) : (
				<div className="sidebar-user text-center">
					<img
						className="img-90 rounded-circle user-pfp"
						src={`${API_BASE_URL}/Company/GetAvatar/${companyID}`}
					/>
					<H6
						attrH6={{
							className: "mt-3 f-14 f-w-600",
							id: "profile-header",
						}}
					>
						{`${companyName} `}
						<br />
						{`${name}`}
					</H6>
					<P className="mb-0 font-roboto">{currentJobTitle}</P>
				</div>
			)}
		</Fragment>
	);
};

export default Profile;
