import { Breadcrumbs } from "../../../AbstractElements";
import CardsClass from "./Cards";
import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";

const CandidateCards = () => {
	return (
		<Fragment>
			<Breadcrumbs
				parent="Candidates"
				title="View Candidates"
				mainTitle="View Candidates"
			/>
			<Container fluid={true}>
				<Row>
					<CardsClass />
				</Row>
			</Container>
		</Fragment>
	);
};

export default CandidateCards;
