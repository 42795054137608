import React, { Fragment, useState, useRef, useEffect } from "react";
import { Btn, H4 } from "../../../../AbstractElements";
import axios from "axios";
import AsyncSelect from "react-select/async";
import {
	Row,
	Col,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
} from "reactstrap";
import salary from "../../../TableData/salary";
import genders from "../../../TableData/genders";
import rates from "../../../TableData/rates";
import equity from "../../../TableData/equity";
import experience from "../../../TableData/experience";
import { TopCenter, directiontoaster } from "../../../../Constant";
import { toast } from "react-toastify";
import Provinces from "../../../TableData/Provinces";
import "./styles.css";

const EditCandidateProfile = () => {
	const [_inputValue, setValue] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [file, setFile] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const ref = useRef("");
	const [candidateDocument, setCandidateDocument] = useState({
		documentName: null,
		hasDocument: false,
	});
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	let url = window.location.href;
	let candidateId = url.substring(url.lastIndexOf(":") + 1);

	const [user, setUser] = useState({
		name: "",
		surname: "",
		cell: "",
		email: "",
		rating: "",
		gender: "",
		yearsExperience: "",
		currentSalary: "",
		ee: "",
		idNumber: "",
		currentCompany: "",
		selectedSkills: [],
		currentJobTitle: "",
		notes: "",
		linkedIn: "",
		city: "",
		province: "",
	});

	const {
		name,
		surname,
		cell,
		email,
		rating,
		yearsExperience,
		currentSalary,
		gender,
		idNumber,
		ee,
		currentCompany,
		currentJobTitle,
		notes,
		linkedIn,
		city,
		province,
	} = user;

	const onInputChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
		setFile(e.target.files[0]);
	};

	useEffect(() => {
		loadUser(candidateId);
		loadDocumentData(candidateId);
	}, [candidateId]);

	const loadDocumentData = (candidateId) => {
		axios
			.get(`${API_BASE_URL}/CandidateDocument/DocumentDetails/${candidateId}`)
			.then((response) => {
				let [documentName, hasDocument] = ["N/A", false];
				if (typeof response.data === "object") {
					documentName = response.data.documentName;
					hasDocument = true;
				}
				setCandidateDocument({ documentName, hasDocument });
			})
			.catch((error) => console.error({ error }));
	};

	const uploadCandidateDocument = (candidateId) => {
		const url = `${API_BASE_URL}/CandidateDocument/AddCandidateDocument/${candidateId}`;
		const formData = new FormData();
		formData.append("file", file);
		formData.append("fileName", file.name);

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		axios.post(url, formData, config);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			if (file) {
				uploadCandidateDocument(candidateId);
			}
			await axios.put(`${API_BASE_URL}/Candidate/${candidateId}`, user);

			const skillSetRequestBody = {
				candidateId: parseInt(candidateId),
				skills: selectedSkills.map((e) => e.skillsetId),
			};
			await axios.post(
				`${API_BASE_URL}/CandidateSkillset/AddCandidateSkillset`,
				skillSetRequestBody
			);
			setTimeout(function () {
				window.location.reload();
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	const loadUser = async (candidateId) => {
		const result = await axios.get(`${API_BASE_URL}/Candidate/${candidateId}`);
		setUser(result.data);
	};

	const handleInputChange = (value) => {
		setValue(value);
	};

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	const getSavedskills = async (_inputValue) => {
		const res = await axios.get(
			`${API_BASE_URL}/CandidateSkillset/${candidateId}`
		);
		return await res.data;
	};

	const promiseOptions = async (_inputValue) => {
		let savedSkillsTask = getSavedskills(selectedSkills);
		const res = await axios.get(`${API_BASE_URL}/Skillset`);
		let savedSkills = await savedSkillsTask;
		let allSkills = await res.data;
		setSelectedSkills(savedSkills);
		return allSkills;
	};

	const handleChanges = (event) => {
		if (event.target.files.length > 0) {
			setFile(event.target.files && event.target.files[0]);
		}
	};

	const validateSelectedFile = () => {
		const MAX_FILE_SIZE = 5120;

		if (!file) {
			alert("Please select a file");
			setIsSuccess(false);
			return;
		}

		const fileSizeKiloBytes = file.size / 1024;

		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			setErrorMsg(`${file.name} size is greater than the limit`);
			alert(`${file.name} size is greater than the limit`);
			setIsSuccess(false);
			return;
		}

		setErrorMsg("");
		setIsSuccess(true);
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Candidate edited successfully", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error editing the candidate", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Row>
				<div className="edit-profile">
					<Form id="form-card" onSubmit={onSubmit}>
						<Col xl="12">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>
										Candidate's Personal Details{" "}
										<img
											className="img-40 img-fluid m-r-20 candidate-pfp"
											src={`${API_BASE_URL}/Candidate/GetAvatar/${candidateId}`}
											alt=""
										/>
									</H4>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="6">
											<FormGroup className="mb-3">
												<Label className="form-label">
													First Name <sup className="mandatory">*</sup>
												</Label>
												<Input
													className="form-control"
													type="text"
													name="name"
													id="exampleEmail"
													value={name}
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Surname <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													name="surname"
													id="examplePassword"
													value={surname}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													ID Number <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													minLength="13"
													maxLength="13"
													name="idNumber"
													id="idNumber"
													className="form-control"
													value={idNumber}
													onChange={(e) => onInputChange(e)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Gender <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="gender"
													value={gender}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{genders.map((sex) => (
														<option key={sex}>{sex}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													City <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													id="exampleSelect"
													name="city"
													value={city}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Province <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="province"
													value={province}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{Provinces.map((pronvince) => (
														<option key={pronvince}>{pronvince}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<H4
											attrH4={{
												className: "card-title mb-0 edit-profile-header",
											}}
										>
											Candidate's Contact Details
										</H4>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Email</Label>
												<Input
													type="email"
													name=""
													id="exampleEmail"
													value={email}
													onChange={(e) => onInputChange(e)}
													className="form-control"
													readOnly
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Cellphone Number <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													minLength="10"
													maxLength="10"
													name="cell"
													id="exampleCell"
													value={cell}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">LinkedIn URL</Label>
												<Input
													type="text"
													name="linkedIn"
													value={linkedIn}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<H4
											attrH4={{
												className: "card-title mb-0 edit-profile-header",
											}}
										>
											Candidate's Work Experience
										</H4>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Job Title <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													name="currentJobTitle"
													id="jobTitle"
													value={currentJobTitle}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Current Company</Label>
												<Input
													type="text"
													name="currentCompany"
													id="exampleEmail"
													value={currentCompany}
													className="form-control"
													onChange={(e) => onInputChange(e)}
													placeholder="Leave blank if unemployed"
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Current Salary <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="currentSalary"
													className="form-control"
													value={currentSalary}
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{salary.map((wages) => (
														<option key={wages}>{wages}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Employment Equity <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="ee"
													className="form-control"
													value={ee}
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{equity.map((employment) => (
														<option key={employment}>{employment}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Years Of Experience <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="select"
													name="yearsExperience"
													id="yearsExperience"
													value={yearsExperience}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{experience.map((years) => (
														<option key={years}>{years}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<H4
											attrH4={{
												className: "card-title mb-0 edit-profile-header",
											}}
										>
											Candidate's Skills
										</H4>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">
													Skillset <sup className="mandatory">*</sup>
												</Label>
												<AsyncSelect
													key={candidateId}
													cacheOptions
													defaultOptions
													selectOptions
													getOptionLabel={(e) => e.name}
													getOptionValue={(e) => e.skillsetId}
													value={selectedSkills}
													loadOptions={promiseOptions}
													onInputChange={handleInputChange}
													onChange={handleChange}
													isMulti={true}
													className="form-control"
													id="form-skill"
												/>
											</FormGroup>
										</Col>
										<H4
											attrH4={{
												className: "card-title mb-0 edit-profile-header",
											}}
										>
											Interview
										</H4>
										<Col md="12">
											<Label className="form-label">Interview Notes</Label>
											<textarea
												type="text"
												rows="10"
												className="form-control"
												required={true}
												value={notes}
												id="form-notes"
												name="notes"
												onChange={(e) => onInputChange(e)}
											/>
										</Col>
										<Col md="12" className="mb-4">
											<Label for="exampleSelect">Rating</Label>
											<Input
												type="select"
												name="rating"
												id="rating"
												value={rating}
												onChange={(e) => onInputChange(e)}
												required={true}
											>
												<option key=""></option>
												{rates.map((employment) => (
													<option key={employment}>{employment}</option>
												))}
											</Input>
										</Col>
										<H4
											attrH4={{
												className: "card-title mb-0 edit-profile-header",
											}}
										>
											Upload Documents
										</H4>
										<Col sm="6" md="12">
											<div className="cv-upload">
												<Label for="exampleSelect">
													Upload CV | Document Name:{" "}
													<sup className="mandatory">*</sup>
													{candidateDocument.documentName}
												</Label>
												<input
													type="file"
													className="form-control"
													required={!candidateDocument.hasDocument}
													ref={ref}
													onChange={handleChanges}
													accept=".pdf, .doc, .docx"
												/>
											</div>
										</Col>
										<Col md="12">
											<Btn
												attrBtn={{
													color: "primary",
													type: "submit",
													id: "update-btn",
												}}
											>
												Update Profile
											</Btn>
										</Col>
									</Row>
									<Row></Row>
								</CardBody>
							</Card>
						</Col>
					</Form>
				</div>
			</Row>
		</Fragment>
	);
};
export default EditCandidateProfile;
