import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Col, Card, Container } from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalBookmark from "../ModalBookmark/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs } from "../../AbstractElements";

//states
const BookmarkCandidates = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Bookmarks`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setData(res.data);
				setPageLoaded(true);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The must have was successfully added", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the job", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	const deleteHandler = async (id) => {
		const confirmed = window.confirm(`Are you sure you would like to delete`);
		try {
			if (confirmed) {
				window.location.reload(false);
				const response = await axios.delete(`${API_BASE_URL}/Candidate/${id}`);
			}
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	const openModal = () => {
		return <ModalLink />;
	};

	function nameColumnBodyTemplate(candidate) {
		return `${candidate.name} ${candidate.surname}`;
	}

	function actionColumnBodyTemplate(candidate) {
		return (
			<>
				{/* CV */}
				<a href={`${API_BASE_URL}/CandidateDocument/${candidate.candidateId}`}>
					<i className="candidate-icons" title="Download CV">
						<FaRegFilePdf />
					</i>
				</a>

				{/* Mail */}
				<ModalLink candidateEmail={candidate.email} />

				{/* Edit */}
				<Link to={`/editcandidate/:${candidate.candidateId}`}>
					<i className="candidate-icons" title="Edit Candidate">
						<FaRegEdit />
					</i>
				</Link>

				<ModalBookmark
					candidateName={candidate.name}
					candidateSurname={candidate.surname}
					candidateId={candidate.candidateId}
					bookmarkId={candidate.bookmarkId}
				/>
			</>
		);
	}

	const candidateName = (candidate) => {
		return (
			<>
				<Link to={`/candidatedetails/:${candidate.candidateId}`}>
					<div className="candidate-name">
						{candidate.candidate.name} {candidate.candidate.surname}
					</div>
				</Link>
			</>
		);
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Candidates"
				title="Bookmarked Candidates"
				mainTitle="Bookmarked Candidates"
			/>
			<Container fluid={true}>
				<Col>
					<Card>
						<DataTable
							scrollable
							rowHover
							rowClassName={"mustHaveRow"}
							value={data}
							size={"small"}
							paginator
							rows={25}
							rowsPerPageOptions={[10, 25, 50, 100]}
							filterDisplay={"row"}
						>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								header="Name"
								body={candidateName}
								field="candidate.name"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="candidate.cell"
								header="Cell"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="candidate.city"
								header="City"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="candidate.currentJobTitle"
								header="Job"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="candidate.currentCompany"
								header="Company"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="candidate.currentSalary"
								header="Salary"
							></Column>
							<Column
								className="candidate-icon-list"
								body={actionColumnBodyTemplate}
								header=""
							></Column>
						</DataTable>
					</Card>
				</Col>
			</Container>
		</Fragment>
	);
};

export default BookmarkCandidates;
