import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import ModalLink from "../../../Modal/Modal";
import ModalDelete from "../../../ModalDelete/Modal";
import { LI } from "../../../../AbstractElements";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import AsyncSelect from "react-select/async";
import { TopCenter, directiontoaster } from "../../../../Constant";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import {
	FaRegTrashAlt,
	FaStar,
	FaRegUser,
	FaRegFilePdf,
	FaRegSave,
	FaRegEdit,
} from "react-icons/fa";

import {
	Row,
	Col,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
} from "reactstrap";

const CardsClass = () => {
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [pageLoaded, setPageLoaded] = useState(false);
	const [selectedSkills, setSelectedSkills] = useState();
	const [_inputValue, setValue] = useState([]);

	const downloadCandidateCV = (candidateId) => {
		fetch(`${API_BASE_URL}/CandidateDocument/${candidateId}`).then(
			(response) => {
				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = `${candidateId} .doc`;
					a.click();
				});
			}
		);
	};

	let bookmarkCandidate = async (candidateId) => {
		let userId = sessionStorage.getItem("userId");

		try {
			let res = await axios.post(
				`${API_BASE_URL}/AddUserBookmark`,
				{
					bookmarkUserId: userId,
					candidateId: candidateId,
				},
				{ headers: { "Content-Type": "application/json" } }
			);
			return directiontoaster("directionssuccessToast");
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Candidate`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setData(res.data);
				setPageLoaded(true);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	const openModal = () => {
		return <ModalLink />;
	};

	function nameColumnBodyTemplate(candidate) {
		return `${candidate.name} ${candidate.surname}`;
	}

	function actionColumnBodyTemplate(candidate) {
		return (
			<>
				{/* CV */}
				<a href={`${API_BASE_URL}/CandidateDocument/${candidate.candidateId}`}>
					<i className="candidate-icons" title="Download CV">
						<FaRegFilePdf />
					</i>
				</a>

				{/* Bookmark */}
				<i
					onClick={(e) => bookmarkCandidate(candidate.candidateId)}
					title="Bookmark Candidate"
					className="candidate-icons"
				>
					<FaRegSave />
				</i>

				{/* Mail */}
				<ModalLink candidateEmail={candidate.email} />

				{/* Edit */}
				<Link to={`/editcandidate/:${candidate.candidateId}`}>
					<i className="candidate-icons" title="Edit Candidate">
						<FaRegEdit />
					</i>
				</Link>
				<ModalDelete
					onDelete={() => deleteHandler(candidate.candidateId)}
					deleteMessage={`Are you sure you want to delete ${candidate.name} ${candidate.surname}`}
				/>
			</>
		);
	}

	const deleteHandler = async (candidateId) => {
		try {
			const response = await axios.delete(
				`${API_BASE_URL}/Candidate/${candidateId}`
			);
			directiontoaster("directionssuccessToast");
		} catch (error) {
			directiontoaster("directionsdangerToast");
		}
	};

	const candidateName = (candidate) => {
		return (
			<>
				<Link to={`/candidatedetails/:${candidate.candidateId}`}>
					<div className="candidate-name">
						{candidate.name} {candidate.surname}
					</div>
				</Link>
			</>
		);
	};

	const promiseOptions = async (_inputValue) => {
		const result = await axios.get(`${API_BASE_URL}/Skillset`);
		return result.data;
	};

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	const handleInputChange = (value) => {
		setValue(value);
	};

	const handleSubmit = async (selectedSkillsetNames) => {
		try {
			const skillsetNames = selectedSkillsetNames.map(
				(skillset) => skillset.name
			);
			const queryParam = skillsetNames
				.map((name) => `skillsetNames=${encodeURIComponent(name)}`)
				.join("&");
			const response = await axios.get(
				`${API_BASE_URL}/Candidate/GetCandidatesBySkillset?${queryParam}`
			);

			const candidates = response.data;
			setData(candidates);
		} catch (error) {
			console.error(error);
			toast.error("Error fetching candidates by skillset");
		}
	};

	useEffect(() => {
		if (selectedSkills && Array.isArray(selectedSkills)) {
			handleSubmit(selectedSkills);
		}
	}, [selectedSkills]);

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Candidate was successfully deleted", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error deleting the candidate", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			{!pageLoaded && (
				<div className="loader-box txt-primary">
					<div className="loader-7 txt-primary"></div>
				</div>
			)}
			{pageLoaded && (
				<Col>
					<Card style={{ padding: "20px", id: "skills-card-pick" }}>
						<Col sm="6" md="12">
							<FormGroup>
								<Label className="form-label">Skillset</Label>
								<AsyncSelect
									cacheOptions
									defaultOptions
									selectOptions
									getOptionLabel={(e) => e.name}
									getOptionValue={(e) => e.skillsetId}
									value={selectedSkills}
									loadOptions={promiseOptions}
									onInputChange={handleInputChange}
									onChange={handleChange}
									isMulti={true}
									required={true}
									className="form-control"
									id="form-skill"
								/>
							</FormGroup>
						</Col>
					</Card>
					<Card>
						<DataTable
							className="custom-datatable"
							scrollable
							rowHover
							rowClassName={"mustHaveRow"}
							value={data}
							size={"small"}
							paginator
							rows={25}
							rowsPerPageOptions={[10, 25, 50, 100]}
						>
							<Column
								sortable
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								header="Name"
								body={candidateName}
								field="name"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								field="cell"
								header="Cell"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								field="city"
								header="City"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								field="currentJobTitle"
								header="Job"
							></Column>
							<Column
								sortable
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								field="currentSalary"
								header="Salary"
							></Column>
							<Column
								className="candidate-icon-list"
								body={actionColumnBodyTemplate}
								header=""
							></Column>
						</DataTable>
					</Card>
				</Col>
			)}
		</Fragment>
	);
};

export default CardsClass;
