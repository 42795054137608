import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { useParams } from "react-router-dom";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

const EditNewsCategory = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);
	const { newsProviderId } = useParams();
	const [active, setIsActive] = useState(null);
	const [country, setCountry] = useState([]);
	const [newsProvider, setNewsProvider] = useState({
		name: "",
		url: "",
		countryId: 0,
	});
	const { name, url, countryId } = newsProvider;
	const navigate = useNavigate();

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/NewsProvider/${newsProviderId.slice(1)}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setIsActive(res.data.active);
				setNewsProvider(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/NewsProvider/${newsProviderId.slice(1)}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
				responseType: "arraybuffer",
			})
			.then((res) => {
				const base64 = btoa(
					new Uint8Array(res.data).reduce(
						(data, byte) => data + String.fromCharCode(byte),
						""
					)
				);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Country`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setCountry(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	const onInputChange = (e) => {
		setNewsProvider({ ...newsProvider, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		try {
			const requestUrl = `${API_BASE_URL}/NewsProvider/EditNewsProvider/${newsProviderId.slice(
				1
			)}`;

			const payload = {
				Name: name,
				Url: url,
				CountryId: countryId,
				Active: active,
			};

			const response = await axios.put(requestUrl, payload);

			setTimeout(function () {
				navigate(`/ViewNewsProvider`);
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("News Provider was successfully edited", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error editing the Provider", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="News Feeds"
				title="Provider"
				mainTitle="Edit News Provider"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={onSubmit}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										value={name}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Url:
									</Label>
									<Input
										type="text"
										name="url"
										required={true}
										className="form-control"
										value={url}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Country:
									</Label>
									<select
										name="countryId"
										value={countryId}
										onChange={onInputChange}
										className="form-control"
									>
										{country.map((country) => (
											<option key={country.countryId} value={country.countryId}>
												{country.countryName}
											</option>
										))}
									</select>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="active"
										checked={active}
										onChange={(e) => setIsActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Update
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewNewsProvider`}>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default EditNewsCategory;
