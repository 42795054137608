import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { useParams } from "react-router-dom";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

//states
const EditCandidateTemplates = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);
	const { candidateQuestionTemplateId } = useParams();
	const [active, setIsActive] = useState(null);
	const navigate = useNavigate();

	const [CandidateTemplate, setCandidateTemplate] = useState({
		candidateQuestion: "",
	});

	const { candidateQuestion } = CandidateTemplate;

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(
				`${API_BASE_URL}/CandidateQuestionTemplate/${candidateQuestionTemplateId.slice(
					1
				)}`,
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
					cancelToken: source.token,
				}
			)
			.then((res) => {
				setIsActive(res.data.active);
				setCandidateTemplate(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(
				`${API_BASE_URL}/CandidateQuestionTemplate/${candidateQuestion.slice(
					1
				)}`,
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
					cancelToken: source.token,
					responseType: "arraybuffer",
				}
			)
			.then((res) => {
				const base64 = btoa(
					new Uint8Array(res.data).reduce(
						(data, byte) => data + String.fromCharCode(byte),
						""
					)
				);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);
	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/CandidateQuestionTemplate`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setCandidateTemplate(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);
	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/CandidateQuestionTemplate`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setCandidateTemplate(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);
	const onInputChange = (e) => {
		setCandidateTemplate({
			...CandidateTemplate,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		setTimeout(function () {
			navigate(`/ViewCandidateQuestionTemplates`);
		}, 2000);

		try {
			const requestUrl = `${API_BASE_URL}/CandidateQuestionTemplate/EditCandidateQuestion/${candidateQuestionTemplateId.slice(
				1
			)}`;

			const payload = {
				candidateQuestion: candidateQuestion,
				active: active,
			};

			const response = await axios.put(requestUrl, payload);
			console.log(response.data);
			setTimeout(function () {
				window.location.reload();
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Candidate Template was successfully edited", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error editing the Template", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Container fluid={true}>
			<Breadcrumbs
				parent="Master Data"
				title="Candidate Template - Edit Candidate Template"
				mainTitle="News Management - Edit Candiate Template"
			/>
			<Fragment>
				<Card>
					<CardBody>
						<Form onSubmit={onSubmit}>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Candidate Question:
									</Label>
									<Input
										type="text"
										name="candidateQuestion"
										required={true}
										className="form-control"
										value={candidateQuestion}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={active}
										onChange={(e) => setIsActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Update
									</Btn>
									<Link
										to={`${process.env.PUBLIC_URL}/ViewCandidateQuestionTemplates`}
									>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Fragment>
		</Container>
	);
};

export default EditCandidateTemplates;
