import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

const AddTest = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	// const [skillTestId] = useState(7);
	//
	const [Name, setName] = useState("");
	const [Description, setDescription] = useState("");
	const [Active, setActive] = useState(false);
	const [image, setImage] = useState([]);
	let Id = 7;
	const navigate = useNavigate();

	const postBadge = async (e) => {
		e.preventDefault();

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		try {
			const formData = new FormData();
			formData.append("image", image);

			let requestUrl = `${API_BASE_URL}/SkillsTest/AddSkillsTest?name=${Name}`;
			requestUrl = `${requestUrl}&description=${Description}`;
			requestUrl = `${requestUrl}&active=${Active}`;
			requestUrl = `${requestUrl}&id=${Id}`;

			const badgePost = await axios
				.post(requestUrl, formData, config)
				.then((res) => console.log(res.data));

			setTimeout(function () {
				window.location.reload("/View-Tests");
			}, 2000);
			navigate(`/View-Tests`);
			return directiontoaster("directionssuccessToast");
		} catch (error) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Test was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the test", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Container fluid={true}>
			<Breadcrumbs parent="Tests" title="Add Test" mainTitle="Add Test" />
			<Fragment>
				<Card>
					<CardBody>
						<Form onSubmit={postBadge}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="g-3">
								<Col md="12">
									<Label className="form-label" for="validationCustom01">
										Test Description
									</Label>
									<textarea
										rows={10}
										type="text"
										name="description"
										required={true}
										className="form-control"
										id="test-question"
										onChange={(e) => setDescription(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Image
									</Label>
									<Input
										type="file"
										required={true}
										className="form-control"
										name="description"
										onChange={(e) => setImage(e.target.files[0])}
									/>
								</Col>
							</Row>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="active"
										checked={Active}
										onChange={(e) => setActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row className="g-3 mt-4">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "badge-btn",
											type: "submit",
										}}
									>
										Add
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/View-Tests`}>
										<Btn attrBtn={{ color: "primary", id: "test-cancel" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Fragment>
		</Container>
	);
};

export default AddTest;
