import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

const AddNewsCategory = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [categoryName, setCategoryName] = useState("");
	const [categoryParentId, setCategoryParentId] = useState("");
	const [parentCategory, setParentCategory] = useState([]);
	const [active, setIsActive] = useState(false);
	const navigate = useNavigate();

	const loadNewsCategory = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/GetAllNewsCategories`);
			const parentCategory = response.data;
			setParentCategory(parentCategory);
		} catch (error) {
			console.error("Error loading News Categories:", error);
			setParentCategory([]);
		}
	};

	useEffect(() => {
		loadNewsCategory();
	}, []);

	const postBadge = async (e) => {
		e.preventDefault();

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		try {
			let requestUrl = `${API_BASE_URL}/AddNewsCategory?CategoryName=${categoryName}`;
			requestUrl = `${requestUrl}&CategoryParentId=${categoryParentId}`;

			requestUrl = `${requestUrl}&active=${active}`;

			const badgePost = await axios
				.post(requestUrl, config)
				.then((res) => console.log(res.data));

			setTimeout(function () {
				navigate(`/ViewNewsCategories`);
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch (error) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Category was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the category", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="News Feeds"
				title="Categories"
				mainTitle="Add News Category"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={postBadge}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Category-Name:
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setCategoryName(e.target.value)}
									/>
								</Col>
							</Row>
							<Col md="4">
								<Label className="form-label" for="validationCustom01">
									Parent-CategoryId:
								</Label>
								<select
									className="form-select"
									onChange={(e) => setCategoryParentId(e.target.value)}
									value={categoryParentId}
								>
									<option value="">Select Category:</option>
									{parentCategory.map((category) => (
										<option
											key={category.newsCategoryId}
											value={category.newsCategoryId}
										>
											{category.categoryName}
										</option>
									))}
								</select>
							</Col>
							<Row></Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={active}
										onChange={(e) => setIsActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Add
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewNewsCategories`}>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default AddNewsCategory;
