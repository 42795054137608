import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

const AddNewsCategory = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [tag, setTag] = useState("");
	const [newsCategoryId, setcategoryId] = useState("");
	const [active, setActive] = useState("");
	const [newsCategory, setNewsCategory] = useState([]);
	const navigate = useNavigate();

	const loadNewsCategory = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/GetAllNewsCategories`);
			const newsCategory = response.data;
			setNewsCategory(newsCategory);
		} catch (error) {
			console.error("Error loading news categories Tag:", error);
			setNewsCategory([]);
		}
	};

	useEffect(() => {
		loadNewsCategory();
	}, []);

	const postNewsCategoryTag = async (e) => {
		e.preventDefault();

		const requestData = {
			NewsCategoryId: newsCategoryId,
			Tag: tag,
			Active: active,
		};

		try {
			const response = await axios.post(
				`${API_BASE_URL}/NewsCategoryTag/AddNewsCategoryTag`,
				requestData
			);
			console.log(response.data);

			setTimeout(function () {
				navigate(`/ViewNewsCategoryTag`);
			}, 2000);

			directiontoaster("directionssuccessToast");
		} catch (error) {
			directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The tag was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the tag", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="News Feeds"
				title="Tags"
				mainTitle="Add News Category Tag"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={postNewsCategoryTag}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Tag:
									</Label>
									<Input
										type="text"
										name="tag"
										required={true}
										className="form-control"
										onChange={(e) => setTag(e.target.value)}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Category-Name:
									</Label>
									<select
										className="form-select"
										onChange={(e) => setcategoryId(e.target.value)}
										value={newsCategoryId}
									>
										<option value="">Select Category</option>
										{newsCategory.map(
											(
												category // Updated variable name to 'category'
											) => (
												<option
													key={category.newsCategoryId}
													value={category.newsCategoryId}
												>
													{category.categoryName}
												</option>
											)
										)}
									</select>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="active"
										checked={active}
										onChange={(e) => setActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Add
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewNewsCategoryTag`}>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default AddNewsCategory;
