import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import EditJobNew from "./EditJobNew";
//returns jobs
const EditJobComponent = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Edit Job" parent="Jobs" title="Edit Job" />
			<Container fluid={true}>
				<EditJobNew />
			</Container>
		</Fragment>
	);
};
export default EditJobComponent;
