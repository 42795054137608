import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import { toast } from "react-toastify";

import {
	Row,
	Col,
	Card,
	Form,
	Label,
	Input,
	CardHeader,
	CardBody,
	Table,
	Container,
} from "reactstrap";

//states
const EditSkills = (e) => {
	let url = window.location.href;
	let SkillId = url.substring(url.lastIndexOf(":") + 1);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const [skill, setSkill] = useState({
		name: "",
	});

	const { name } = skill;

	const onInputChange = (e) => {
		setSkill({ ...skill, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		loadSkill(SkillId);
	}, [SkillId]);

	const loadSkill = async (SkillId) => {
		const result = await axios.get(`${API_BASE_URL}/Skills/${SkillId}`);
		setSkill(result.data);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		await axios.put(`${API_BASE_URL}/Skills/${SkillId}`, skill);
		window.location.href = "/viho/skills";
		return false;
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The skill was successfully added", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the skill", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs parent="Master Data" title="Skills" mainTitle="Skills" />
			<Container fluid={true}>
				<Card>
					<CardHeader className="pb-0"></CardHeader>
					<CardBody>
						<Form onSubmit={onSubmit}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										value={name}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
								<Row className="g-3">
									<Col md="4">
										<Btn type="submit" attrBtn={{ color: "primary" }}>
											{"Update Skill"}
										</Btn>
									</Col>
								</Row>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default EditSkills;
