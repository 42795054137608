import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { useParams } from "react-router-dom";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

const EditNewsCategoryTag = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);
	const { newsCategoryTagId } = useParams();
	const [active, setIsActive] = useState(null);
	const [category, setCategory] = useState([]);
	const navigate = useNavigate();

	const [newsCategoryTag, setNewsCategoryTag] = useState({
		tag: "",
		newsCategoryId: "",
	});

	const { tag, newsCategoryId } = newsCategoryTag;

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(
				`${API_BASE_URL}/NewsCategoryTag/GetNewsCategoryTag/${newsCategoryTagId.slice(
					1
				)}`,
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
					cancelToken: source.token,
				}
			)
			.then((res) => {
				setIsActive(res.data.active);
				setNewsCategoryTag(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(
				`${API_BASE_URL}/NewsCategoryTag/GetNewsCategoryTag/${newsCategoryTagId.slice(
					1
				)}`,
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
					cancelToken: source.token,
					responseType: "arraybuffer",
				}
			)
			.then((res) => {
				const base64 = btoa(
					new Uint8Array(res.data).reduce(
						(data, byte) => data + String.fromCharCode(byte),
						""
					)
				);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/GetAllNewsCategories`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setCategory(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	const onInputChange = (e) => {
		setNewsCategoryTag({ ...newsCategoryTag, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		try {
			const requestUrl = `${API_BASE_URL}/NewsCategoryTag/EditNewsCategoryTag/${newsCategoryTagId.slice(
				1
			)}`;

			const payload = {
				Tag: tag,
				NewsCategoryId: newsCategoryId,
				Active: active,
			};

			const response = await axios.put(requestUrl, payload);
			console.log(response.data);
			setTimeout(function () {
				navigate(`/ViewNewsCategoryTag`);
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("News category tag was successfully edited", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error editing the news category tag", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="News Feeds"
				title="Tags"
				mainTitle="Edit News Category Tag"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={onSubmit}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Tag:
									</Label>
									<Input
										type="text"
										name="tag"
										required={true}
										className="form-control"
										value={tag}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Category-Name:
									</Label>
									<select
										name="newsCategoryId"
										value={newsCategoryId}
										onChange={onInputChange}
										className="form-control"
									>
										{category.map((category) => (
											<option
												key={category.newsCategoryId}
												value={category.newsCategoryId}
											>
												{category.categoryName}
											</option>
										))}
									</select>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={active}
										onChange={(e) => setIsActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Update
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewNewsCategoryTag`}>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default EditNewsCategoryTag;
