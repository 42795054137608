import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Col, Card, Container } from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import ModalDeleteBadge from "../ModalDeleteBadge/ModalBadge";
import ModelNewsFeed from "../ModalDeleteNewsFeed/ModalNewsFeed";
import ModelDeleteNewsCategory from "../ModelDeleteNewsCategory/ModelDeleteNewsCategory";
import "./styles.css";
import ModalDelete from "../ModalDelete/Modal";

const ViewNewsCategory = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);

	const fetchCategories = () => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		const categories = axios
			.get(`${API_BASE_URL}/GetAllNewsCategories`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setData(res.data);
				setPageLoaded(true);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The category was successfully deleted", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error deleting the category", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	const deleteHandler = async (categoryId) => {
		try {
			const response = await axios.delete(
				`${API_BASE_URL}/NewsCategory/${categoryId}`
			);
			fetchCategories();
			directiontoaster("directionssuccessToast");
		} catch (error) {
			console.error("Something went wrong", error);
			directiontoaster("directionsdangerToast");
		}
	};

	function actionColumnBodyTemplate(newsCategory) {
		return (
			<>
				<Link to={`/Edit-NewsCategory/:${newsCategory.newsCategoryId}`}>
					<i className="candidate-icons" title="Edit News Category">
						<FaRegEdit />
					</i>
				</Link>

				<ModalDelete
					onDelete={() => deleteHandler(newsCategory.newsCategoryId)}
					deleteMessage={`Are you sure you would like to delete ${newsCategory.categoryName}?`}
				/>
			</>
		);
	}

	return (
		<Fragment>
			<Breadcrumbs
				parent="News Feeds"
				title="Categories"
				mainTitle="News Categories"
			/>
			<Container fluid={true}>
				<Col>
					<Card>
						<DataTable
							scrollable
							rowHover
							rowClassName={"mustHaveRow"}
							value={data}
							size={"small"}
							paginator
							rows={25}
							rowsPerPageOptions={[10, 25, 50, 100]}
							filterDisplay={"row"}
							id="badge-table"
						>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								header="Category"
								field="categoryName"
							></Column>
							<Column
								className="candidate-icon-list"
								body={actionColumnBodyTemplate}
								header=""
							></Column>
						</DataTable>
						<Link to={`${process.env.PUBLIC_URL}/AddNewsCategories`}>
							<Btn attrBtn={{ color: "primary", id: "category-btn" }}>
								Add Category
							</Btn>
						</Link>
					</Card>
				</Col>
			</Container>
		</Fragment>
	);
};

export default ViewNewsCategory;
