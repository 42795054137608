import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../AbstractElements";
import ModalLink from "../Modal/Modal";
import { Col, Card, Container } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";

const JobApplicants = () => {
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [rating, setRating] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	let url = window.location.href;
	let jobId = url.substring(url.lastIndexOf(":") + 1);

	const deleteHandler = async (id) => {
		const confirmed = window.confirm(`Are you sure you would like to delete`);
		try {
			if (confirmed) {
				window.location.reload(false);
				const response = await axios.delete(`${API_BASE_URL}/Candidate/${id}`);
			}
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	const downloadCandidateCV = (candidateId) => {
		fetch(`${API_BASE_URL}/CandidateDocument/${candidateId}`).then(
			(response) => {
				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = `${candidateId} .doc`;
					a.click();
				});
			}
		);
	};

	let bookmarkCandidate = async (candidateId) => {
		let userId = sessionStorage.getItem("token");

		try {
			let res = await axios.post(
				`${API_BASE_URL}/AddUserBookmark`,
				{
					bookmarkUserId: "61839665-d783-4363-8edd-822032f1a846",
					candidateId: candidateId,
				},
				{ headers: { "Content-Type": "application/json" } }
			);
		} catch (err) {
			alert(err);
		}
	};

	useEffect(() => {
		let isMounted = true;
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/CandidateJobApply/${jobId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				if (isMounted) {
					const candidateList = res.data.map((elem) => {
						return elem;
					});
					setData(candidateList);
				}
			})
			.catch((err) => {
				console.err(err);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const openModal = () => {
		return <ModalLink />;
	};

	function nameColumnBodyTemplate(candidate) {
		return `${candidate.name} ${candidate.surname}`;
	}

	const candidateName = (candidate) => {
		return (
			<>
				<Link to={`/candidatedetails/:${candidate.candidateId}`}>
					<div className="candidate-name">
						{candidate.name} {candidate.surname}
					</div>
				</Link>
			</>
		);
	};

	function actionColumnBodyTemplate(candidate) {
		return (
			<>
				{/* CV */}
				<a href={`${API_BASE_URL}/CandidateDocument/${candidate.candidateId}`}>
					<i className="candidate-icons" title="Download CV">
						<FaRegFilePdf />
					</i>
				</a>

				{/* Bookmark */}
				<i
					// onClick={(e) => bookmarkCandidate(candidate.candidateId)}
					title="Bookmark Candidate"
					className="candidate-icons"
				>
					<FaRegSave />
				</i>

				{/* Mail */}
				<ModalLink candidateEmail={candidate.email} />

				{/* Edit */}
				<Link to={`/editcandidate/:${candidate.candidateId}`}>
					<i className="candidate-icons" title="Edit Candidate">
						<FaRegEdit />
					</i>
				</Link>
			</>
		);
	}

	return (
		<Container fluid={true}>
			<Breadcrumbs
				parent="Jobs"
				title="View Applicants"
				mainTitle="View Applicants"
			/>
			<Fragment>
				<Col>
					<Card>
						<DataTable
							scrollable
							rowHover
							rowClassName={"mustHaveRow"}
							value={data}
							size={"small"}
							paginator
							rows={25}
							rowsPerPageOptions={[10, 25, 50, 100]}
							filterDisplay={"row"}
						>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								body={candidateName}
								field="name"
								header="Name"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="cell"
								header="Cell"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="city"
								header="City"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="currentJobTitle"
								header="Job"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="currentCompany"
								header="Company"
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="currentSalary"
								header="Salary"
							></Column>
							<Column
								className="candidate-icon-list"
								body={actionColumnBodyTemplate}
								header=""
							></Column>
						</DataTable>
					</Card>
				</Col>
			</Fragment>
		</Container>
	);
};

export default JobApplicants;
