import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../AbstractElements";
import ModalLink from "../Modal/Modal";
import { Col, Card, CardBody, Table, Container } from "reactstrap";

const JobList = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [jobs, setJobs] = useState([]);

	useEffect(() => {
		let isMounted = true;

		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/Job`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				if (isMounted) {
					setJobs(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const openModal = () => {
		return <ModalLink />;
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Jobs"
				title="View Applicants"
				mainTitle="View Applicants"
			/>
			<Container fluid={true}>
				<CardBody>
					<Col>
						<Card>
							<Table>
								<thead>
									<tr>
										<th>Job Listings</th>
									</tr>
								</thead>
								<tbody>
									{jobs.map(({ jobId, jobTitle }) => {
										return (
											<tr key={jobTitle} className="mustHaveRow">
												<td>
													<Link to={`/ViewJobApplicants/:${jobId}`}>
														{jobTitle}
													</Link>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Card>
					</Col>
				</CardBody>
			</Container>
		</Fragment>
	);
};

export default JobList;
