import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Col, Card, Container } from "reactstrap";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import ModalDelete from "../ModalDelete/Modal";

const ViewNewsFeed = () => {
  const API_BASE_URL = window.appConfig.API_BASE_URL;
  const [data, setData] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(false);
  const roleId = sessionStorage.getItem("roleId");

  const fetchCompanyContacts = async () => {
    const authToken = sessionStorage.getItem("token");
    const companyID = sessionStorage.getItem("companyID");
    try {
      const response = await axios.get(`${API_BASE_URL}/Recruit/${companyID}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data);
      setPageLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompanyContacts();
  }, []);

  const directiontoaster = (toastname) => {
    switch (toastname) {
      case "directionssuccessToast":
        toast.success("The recruit contact was successfully deleted", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
        break;
      case "directionsdangerToast":
        toast.error("There was an error deleting the recruit contact", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
        break;
    }
  };

  const deleteHandler = async (recruitContactId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/Recruit/DeleteNewsRecruiterContact/${recruitContactId}`
      );
      fetchCompanyContacts();
      directiontoaster("directionssuccessToast");
    } catch (error) {
      console.error("Something went wrong", error);
      if (error.response && error.response.status === 400) {
        toast.error("You are not authorized to delete this recruit contact", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      } else {
        directiontoaster("directionsdangerToast");
      }
    }
  };

  const categoryNameTemplate = (rowData) => {
    return rowData.applicationRoles ? rowData.applicationRoles.roleName : "";
  };

  function actionColumnBodyTemplate(recruit) {
    if (roleId === "4") {
      return (
        <>
          {roleId === "4" && (
            <Link to={`/EditRecruitContact/:${recruit.recruitContactId}`}>
              <i className="candidate-icons" title="Edit Recruit Contact">
                <FaRegEdit />
              </i>
            </Link>
          )}
          <ModalDelete
            onDelete={() => deleteHandler(recruit.recruitContactId)}
            deleteMessage={`Are you sure you would like to delete?`}
          />
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <Fragment>
      <Breadcrumbs
        parent="User Management"
        title="User Management"
        mainTitle="User Management"
      />
      <Container fluid={true}>
        <Col>
          <Card>
            <DataTable
              scrollable
              rowHover
              rowClassName={"mustHaveRow"}
              value={data}
              size={"small"}
              paginator
              rows={25}
              rowsPerPageOptions={[10, 25, 50, 100]}
              filterDisplay={"row"}
              id="badge-table"
            >
              <Column
                filterHeaderClassName="p-inputtext-sm"
                showFilterMenu={false}
                filter={true}
                header="Name"
                field="name"
              ></Column>

              <Column
                filterHeaderClassName="p-inputtext-sm"
                showFilterMenu={false}
                filter={true}
                header="Surname"
                field="surname"
              ></Column>
              <Column
                filterHeaderClassName="p-inputtext-sm"
                showFilterMenu={false}
                filter={true}
                header="Email"
                field="email"
              ></Column>

              <Column
                className="candidate-icon-list"
                body={actionColumnBodyTemplate}
                header=""
              ></Column>
            </DataTable>

            {roleId === "4" && (
              <Link to={`${process.env.PUBLIC_URL}/AddRecruitContact`}>
                <Btn attrBtn={{ color: "primary", id: "feed-badge" }}>
                  Add Contact
                </Btn>
              </Link>
            )}
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default ViewNewsFeed;
