import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import "./styles.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/ext-language_tools";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TopCenter, directiontoaster } from "../../Constant";
import { useNavigate } from "react-router-dom";

import {
	Row,
	Col,
	Card,
	Form,
	Label,
	Input,
	CardBody,
	Container,
} from "reactstrap";

const EditQuestions = () => {
	const [testName, setTestName] = useState("");
	const [question, setQuestion] = useState("");
	const [questionCode, setQuestionCode] = useState("");
	const [answers, setAnswers] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	let count = 0;
	let url = window.location.href;
	let skillTestId = url.substring(url.lastIndexOf(":") + 1);
	const [answer1, setAnswer1] = useState("");
	const [answer2, setAnswer2] = useState("");
	const [answer3, setAnswer3] = useState("");
	const [answer4, setAnswer4] = useState("");

	const [selectedCheckbox, setSelectedCheckbox] = useState(null);
	const [answer1IsCorrect, setAnswer1IsCorrect] = useState(false);
	const [answer2IsCorrect, setAnswer2IsCorrect] = useState(false);
	const [answer3IsCorrect, setAnswer3IsCorrect] = useState(false);
	const [answer4IsCorrect, setAnswer4IsCorrect] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const res = axios
			.get(`${API_BASE_URL}/SkillsTest/WIthQuestions/${skillTestId}`)
			.then((response) => {
				setTestName(response.data.name);
			});
	}, []);

	const postEditTest = async (e) => {
		e.preventDefault();
		setIsSaving(true);

		try {
			let res = await axios.post(
				`${API_BASE_URL}/SkillsTestQuestion/AddSkillsTestQuestion`,
				{
					skillTestQuestionId: 0,
					skillTestId: skillTestId,
					question: question,
					questionCode: questionCode,
					answers: [
						{
							answer: answer1,
							isCorrect: answer1IsCorrect,
							skillTestAnswerId: 0,
							skillTestQuestionId: 0,
						},
						{
							answer: answer2,
							isCorrect: answer2IsCorrect,
							skillTestAnswerId: 0,
							skillTestQuestionId: 0,
						},
						{
							answer: answer3,
							isCorrect: answer3IsCorrect,
							skillTestAnswerId: 0,
							skillTestQuestionId: 0,
						},
						{
							answer: answer4,
							isCorrect: answer4IsCorrect,
							skillTestAnswerId: 0,
							skillTestQuestionId: 0,
						},
					],
				},
				{ headers: { "Content-Type": "application/json" } }
			);
//
			setTimeout(function () {
				navigate(`/View-Test-Questions:${skillTestId}`);
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch (err) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Question added successfully", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the question", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Tests"
				title="Add Question"
				mainTitle={`Add Question : ${testName}`}
			/>
			<Container fluid={true}>
				<Form onSubmit={postEditTest} method="POST">
					<Card>
						<CardBody>
							<Row className="g-3">
								<Col md="12">
									<Label className="form-label" for="validationCustom01">
										Question
									</Label>
									<textarea
										rows={10}
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setQuestion(e.target.value)}
										id="test-question"
										value={question}
									/>
								</Col>
								<Col md="12">
									<Label className="form-label" for="validationCustom01">
										Code (optional)
									</Label>
									<AceEditor
										mode="javascript"
										theme="twilight"
										onChange={(value) => {
											setQuestionCode(value);
										}}
										value={questionCode}
										name="code-editor"
										editorProps={{ $blockScrolling: true }}
										showPrintMargin={false}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							<Col md="12">
								<Label className="form-label" for="validationCustom02">
									Answer 1
								</Label>
								<Input
									type="text"
									required={true}
									className="form-control"
									name="description"
									onChange={(e) => setAnswer1(e.target.value)}
								/>
								<div className="check-div">
									<input
										type="checkbox"
										checked={selectedCheckbox === "answer1IsCorrect"}
										onChange={() => {
											setSelectedCheckbox("answer1IsCorrect");
											setAnswer1IsCorrect(true);
											setAnswer2IsCorrect(false);
											setAnswer3IsCorrect(false);
											setAnswer4IsCorrect(false);
										}}
									/>
									<label htmlFor="louie" className="radio-label">
										Is correct?
									</label>
								</div>
							</Col>
							<Col md="12">
								<Label className="form-label" for="validationCustom02">
									Answer 2
								</Label>
								<Input
									type="text"
									required={true}
									className="form-control"
									name="description"
									onChange={(e) => setAnswer2(e.target.value)}
								/>
								<div className="check-div">
									<input
										type="checkbox"
										checked={selectedCheckbox === "answer2IsCorrect"}
										onChange={() => {
											setSelectedCheckbox("answer2IsCorrect");
											setAnswer2IsCorrect(true);
											setAnswer1IsCorrect(false);
											setAnswer3IsCorrect(false);
											setAnswer4IsCorrect(false);
										}}
									/>
									<label htmlFor="louie" className="radio-label">
										Is correct?
									</label>
								</div>
							</Col>
							<Col md="12">
								<Label className="form-label" for="validationCustom02">
									Answer 3
								</Label>
								<Input
									type="text"
									required={true}
									className="form-control"
									name="description"
									onChange={(e) => setAnswer3(e.target.value)}
								/>
								<div className="check-div">
									<input
										type="checkbox"
										checked={selectedCheckbox === "answer3IsCorrect"}
										onChange={() => {
											setSelectedCheckbox("answer3IsCorrect");
											setAnswer3IsCorrect(true);
											setAnswer1IsCorrect(false);
											setAnswer2IsCorrect(false);
											setAnswer4IsCorrect(false);
										}}
									/>
									<label htmlFor="louie" className="radio-label">
										Is correct?
									</label>
								</div>
							</Col>
							<Col md="12">
								<Label className="form-label" for="validationCustom02">
									Answer 4
								</Label>
								<Input
									type="text"
									required={true}
									className="form-control"
									name="description"
									onChange={(e) => setAnswer4(e.target.value)}
								/>
								<div className="check-div">
									<input
										type="checkbox"
										checked={selectedCheckbox === "answer4IsCorrect"}
										onChange={() => {
											setSelectedCheckbox("answer4IsCorrect");
											setAnswer4IsCorrect(true);
											setAnswer1IsCorrect(false);
											setAnswer2IsCorrect(false);
											setAnswer3IsCorrect(false);
										}}
									/>
									<label htmlFor="louie" className="radio-label">
										Is correct?
									</label>
								</div>
							</Col>
							<br />
							<br />
							<Row className="g-3">
								<Col md="12">
									<Btn
										type="submit"
										attrBtn={{
											color: "primary",
											disabled: isSaving ? "disabled" : "",
										}}
									>
										{"Add Question"}
									</Btn>
									&nbsp; &nbsp;
									<Link
										to={`${process.env.PUBLIC_URL}/View-Test-Questions:${skillTestId}`}
									>
										<Btn type="submit" attrBtn={{ color: "info" }}>
											{"Cancel"}
										</Btn>
									</Link>
								</Col>
							</Row>
							<br />
						</CardBody>
					</Card>
				</Form>
			</Container>
		</Fragment>
	);
};

export default EditQuestions;
