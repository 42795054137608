import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

//states to store data
const AddCandidateQuestionTemplate = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [candidateQuestion, setCandidateQuestion] = useState("");
	const navigate = useNavigate();
	const [active, setActive] = useState(true);
	let badgeId;


	const loadTemplates = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/Candidates`);
			const CandidateQuestion = response.data;
			setCandidateQuestion(CandidateQuestion);
		} catch (error) {
			console.error("Error loading candidate templates:", error);
			setCandidateQuestion([]);
		}
	};


	useEffect(() => {

		loadTemplates();
	}, []);
	const postNewsProvider = async (e) => {
		e.preventDefault();

		const requestData = {
			CandidateQuestion: candidateQuestion,
			Active: active
		};

		try {
			const response = await axios.post(`${API_BASE_URL}/CandidateQuestionTemplate/AddCandidateQuestion`, requestData);
			console.log(response.data); // Assuming you want to log the response data

			setTimeout(function () {

				navigate(`/ViewCandidateQuestionTemplates`);

			}, 2000);


			directiontoaster("directionssuccessToast");
		} catch (error) {
			directiontoaster("directionsdangerToast");
		}
	};


//
	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Template has been successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("Sorry there was an error adding the template", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Container fluid={true}>
			<Breadcrumbs
				parent="Master Data"
				title="Candidate Question Template - Add Template"
				mainTitle="Candidate Question Template - Add Template"
			/>
			<Fragment>
				<Card>
					<CardBody>
						<Form onSubmit={postNewsProvider}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Candidate Template:
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setCandidateQuestion(e.target.value)}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={active}
										defaultChecked={true}
										onChange={(e) => setActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn

										attrBtn={{
											color: "primary",
											id: "badge-btn",
											type: "submit",
										}}
									>
										Add
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewCandidateQuestionTemplates`}>
										<Btn attrBtn={{ color: "primary", id: "badge-cancel" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Fragment>
		</Container>
	);
};

export default AddCandidateQuestionTemplate;
