import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
    Col,
    Card,
    Container,
    CardBody,
    Label,
    Form,
    Row,
    Input,
} from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";

const AddNewsProvider = () => {
    const API_BASE_URL = window.appConfig.API_BASE_URL;
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [roleId, setRoleId] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);
    const companyID = sessionStorage.getItem("companyID");

    const loadRoles = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/Account`);
            const roles = response.data;
            setRoles(roles);
        } catch (error) {
            console.error("Error loading roles:", error);
            setRoles([]);
        }
    };

    useEffect(() => {
        loadRoles();
    }, []);

    const postRecruitContact = async (e) => {
        e.preventDefault();

        const requestData = {
            Name: name,
            Surname: surname,
            Email: email,
            CompanyID: companyID,
            RoleId: roleId,
        };

        try {
            const response = await axios.post(
                `${API_BASE_URL}/Recruit/AddRecruitContact`,
                requestData
            );

            

            toast.success("Recruit Contact was successfully added", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
            });

            navigate(`${process.env.PUBLIC_URL}/ViewRecruitContact/:companyID`); // Navigate to the desired page
        } catch (error) {
            toast.error("There was an error adding the Recruit Contact", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
            });
        }
    };

    return (
        <Fragment>
            <Breadcrumbs
                parent="User Management"
                title="Add User Management"
                mainTitle="Add User Management"
            />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Form onSubmit={postRecruitContact}>
                            <Row className="g-3">
                                <Col md="4">
                                    <Label className="form-label" for="validationCustom01">
                                        Name:
                                    </Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        required={true}
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label className="form-label" for="validationCustom01">
                                        Surname:
                                    </Label>
                                    <Input
                                        type="text"
                                        name="surname"
                                        required={true}
                                        className="form-control"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label className="form-label" for="validationCustom01">
                                        Email:
                                    </Label>
                                    <Input
                                        type="text"
                                        name="email"
                                        required={true}
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label className="form-label" for="validationCustom01">
                                        Role-Name:
                                    </Label>
                                    <select
                                        className="form-select"
                                        onChange={(e) => setRoleId(e.target.value)}
                                        value={roleId}
                                    >
                                        <option value="">Select Role</option>
                                        {roles.map((role) => (
                                            <option key={role.roleId} value={role.roleId}>
                                                {role.roleName}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                            <Row id="badge-row">
                                <Col md="4">
                                    <Btn
                                        attrBtn={{
                                            color: "primary",
                                            id: "edit-btns",
                                            type: "submit",
                                        }}
                                    >
                                        Add
                                    </Btn>
                                    <Link to={`${process.env.PUBLIC_URL}/ViewRecruitContact/:companyID`}>
                                        <Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
                                            Cancel
                                        </Btn>
                                    </Link>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default AddNewsProvider;
