import { H6, Image, P, Btn } from "../../../AbstractElements";
import React, { Fragment } from "react";
import { Card, Col, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Interweave } from "interweave";
import "./styles.css";

const ListCard = ({ colClass }) => {
	const [data, setDate] = useState([]);
	const [pageLoaded, setPageLoaded] = useState(false);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	
	useEffect(() => {
		let isMounted = true;
		const authToken = sessionStorage.getItem("token");
		const companyID = sessionStorage.getItem("companyID");
		axios
			.get(`${API_BASE_URL}/Job/job/${companyID}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				if (isMounted) {
					setDate(res.data);
					setPageLoaded(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		return () => {
			isMounted = false;
		};
	}, [API_BASE_URL]);

	return (
		<Fragment>
			{!pageLoaded && (
				<div className="loader-box txt-primary">
					<div className="loader-7 txt-primary"></div>
				</div>
			)}
			{pageLoaded && (
				<Col className={colClass}>
					{data.map((data, index) => (
						<Card key={index} className="job-main-cards">
							<div className="job-search-jobs">
								<CardBody>
									<div className="media" id="list-view-media">
										<Image
											attrImage={{
												className: "img-40 img-fluid m-r-20",
												src: `${require("../../../assets/images/job-search/1.jpg")}`,
												alt: "",
											}}
										/>
										<div className="media-body" id="media-body-view">
											<H6 attrH6={{ className: "f-w-600" }}>
												<Link to={`/Job/:${data.jobId}`}>{data.jobTitle}</Link>
											</H6>
											<P className="f-w-600">
												<span id="job-span">
													{data.jobType} - {data.jobLocation} (
													{data.workplaceType}) | {data.salaryType} -
													{" R " + data.salaryRange}
												</span>
											</P>
										</div>
										<Link to={`/job/:${data.jobId}`} id="job-btn">
											<Btn
												attrBtn={{
													className:
														"btn btn-sm job-apply-btn job-apply-view m-r-10",
													color: "primary",
												}}
											>
												View
											</Btn>
										</Link>
										<Link to={`/EditJob/:${data.jobId}`} id="job-btn">
											<Btn
												attrBtn={{
													className:
														"btn btn-sm job-apply-btn job-apply-view m-r-10",
													color: "primary",
												}}
											>
												Edit
											</Btn>
										</Link>
										<Link to={`/ViewJobApplicants/:${data.jobId}`} id="job-btn">
											<Btn
												attrBtn={{
													className: "btn btn-sm job-apply-btn job-apply-view",
													color: "primary",
												}}
											>
												View Applicants
											</Btn>
										</Link>
									</div>
									<p id="summary-text" style={{ marginTop: "20px" }}>
										<Interweave
											content={
												data.jobSummary.length < 500
													? data.jobSummary
													: data.jobSummary.substring(0, 500) + ["..."]
											}
										/>
									</p>
								</CardBody>
							</div>
						</Card>
					))}
				</Col>
			)}
		</Fragment>
	);
};

export default ListCard;
