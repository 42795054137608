import React, { Fragment, useState, useRef, useEffect } from "react";
import { Btn, H4 } from "../../../../AbstractElements";
import axios from "axios";
import AsyncSelect from "react-select/async";
import "./style.css";
import salary from "../../../TableData/salary";
import genders from "../../../TableData/genders";
import rates from "../../../TableData/rates";
import equity from "../../../TableData/equity";
import experience from "../../../TableData/experience";

import { toast } from "react-toastify";
import Provinces from "../../../TableData/Provinces";
import { Link } from "react-router-dom";
import {
	Row,
	Col,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
} from "reactstrap";

const EditCandidateProfile = () => {
	const companyID = sessionStorage.getItem("companyID");

	const [inputValue, setValue] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [selectedValue, setSelectedValue] = useState([]);
	const [selectedOption, setSelectedOption] = useState([]);
	const [value, setValueMain] = useState("");
	const [file, setFile] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const ref = useRef("");
	const [username, setUsername] = useState("");

	const [user, setUser] = useState({
		name: "",
		address: "",
		email: "",
		telephone: "",
		website: "",
	});

	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const {
		name,
		address,
		email,
		telephone,
		website,
	} = user;

	const handleInputChangeMain = (inputValue) => {
		setValue(inputValue);
	};

	const handleChangeMain = (selectedOption) => {
		setSelectedValue(selectedOption);
		const selectedSkillsetId = selectedOption
			? selectedOption.skillsetId
			: null;
		setUser((prevUser) => ({
			...prevUser,
			mainSkillsetId: selectedSkillsetId,
		}));
	};

	const onInputChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
		if (e.target.type === "file") {
			setFile(e.target.files[0]);
		}
	};
	async function uploadAvatar(err) {
		if (!image) {
			console.log("image is required");
			return false;
		}
		if (!image.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
			console.log("select valid image.");
			throw Error("select valid image.");
		}
	
		const formData = new FormData();
		formData.append("formFile", image);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		await axios.post(
			`${API_BASE_URL}/Company/AddAvatar?companyID=${encodeURIComponent(companyID)}`,
			formData,
			config
		);
	}
	

	const loadUser = async () => {
		const email = sessionStorage.getItem("email");
		const companyName = sessionStorage.getItem('name');
		
		setUsername(email);

		const result = await axios.get(
			`${API_BASE_URL}/Company/GetByEmail?email=${email}`
		);

		setUser(result.data);
		
	};

	useEffect(() => {
		const source = axios.CancelToken.source();

		loadUser();

		return () => {
			source.cancel();
		};
	}, [username]);

	const handleInputChange = (value) => {
		setValue(value);
	};
	const [image, setImage] = useState("");
	function handleImage(e) {
		setImage(e.target.files[0]);
	}

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Profile updating...", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
			case "directionsdangerToast":
				toast.error("Please fill in all the fields", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		e.currentTarget.disabled = true;

		if (
			name !== "" &&
			address !== "" &&
			telephone !== "" &&
			website !== ""
		) {
			try {
				await uploadAvatar();
				await axios.put(`${API_BASE_URL}/Company/${companyID}`, user);

				setTimeout(function () {
					window.location.href = "Edit-Profile";
				}, 1500);

				return directiontoaster("directionssuccessToast");
			} catch (err) {
				let errorMessge = err.toString();
				toast.error(errorMessge, { position: toast.POSITION.TOP_CENTER });
			}
		}
	};

	return (
		<Fragment>
			<Row>
				<div className="edit-profile">
					<Form id="form-card" onSubmit={onSubmit}>
						<Col xl="12">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>
										Company Profile
									</H4>
									<Label className="form-label" style={{ marginTop: "8px" }}>
										Please update the fields below with your information. Your
										profile will be used when applying for positions or
										opportunities on the website. Visit us frequently to keep
										your information up to date.
									</Label>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="6">
											<FormGroup className="mb-3">
												<Label className="form-label">
														Company Name{" "}
													<sup className="mandatory">*</sup>
												</Label>
												<Input
													className="form-control"
													required
													type="text"
													name="name"
													id="exampleEmail"
													value={name}
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Address <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													name="address"
													required
													id="examplePassword"
													value={address}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Telephone <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													name="telephone"
													id="telephone"
													className="form-control"
													value={telephone}
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Email <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													id="exampleSelect"
													required
													name="email"
													value={email}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Website <sup className="mandatory">*</sup>
												</Label>
												<Input
													type="text"
													id="exampleSelect"
													required
													name="website"
													value={website}
													className="form-control"
													onChange={(e) => onInputChange(e)}
												></Input>
											</FormGroup>
										</Col>
										<Col md="12">
											<FormGroup>
												<Label for="exampleSelect">
												 Upload	Company Logo
												</Label>
												<input
													type="file"
													name="file"
													onChange={handleImage}
													className="form-control"
													accept=".png, .jpg, .jpeg, .tiff"
												/>
											</FormGroup>
										</Col>
									</Row>
                                    <Col md="12">
									<FormGroup>
										<Btn
											attrBtn={{
												color: "primary",
												type: "submit",
												id: "update-btn",
											}}
										>
											Update Profile
										</Btn>
									</FormGroup>
								</Col>
							
								</CardBody>
							</Card>
							
								
						</Col>
					</Form>
				</div>
			</Row>
		</Fragment>
	);
};

export default EditCandidateProfile;
