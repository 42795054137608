import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { loginProvider } from "../Config/Config";

const PrivateRoute = () => {
	const [currentUser, setCurrentUser] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const jwt_token = sessionStorage.getItem("token");

	useEffect(() => {
		loginProvider.onAuthStateChanged(() => setCurrentUser(true));
		setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
		localStorage.setItem("authenticated", authenticated);
		localStorage.setItem("currentUser", currentUser);
	}, []);

	return jwt_token ? <Outlet /> : <Navigate exact to={`login`} />;
};

export default PrivateRoute;
