import { HardDrive, User, Briefcase } from "react-feather";
import {
	FaPeopleArrows,
	FaNetworkWired,
	FaFileAlt,
	FaEnvelope,
} from "react-icons/fa";

export const MENUITEMS = [
	{
		menucontent: "Dashboards,Widgets",
		Items: [
			{
				title: "Candidates",
				icon: User,
				type: "sub",
				active: false,
				children: [
					{
						path: `${process.env.PUBLIC_URL}/View-Candidates`,
						title: "View Candidates",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/Add-Candidates`,
						title: "Add Candidate",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/Bookmarked-Candidates`,
						title: "Bookmarked",
						type: "link",
					},
				],
			},
			{
				title: "Jobs",
				icon: Briefcase,
				type: "sub",
				active: false,
				children: [
					{
						path: `${process.env.PUBLIC_URL}/View-Jobs`,
						title: "View Jobs",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/AddNewJob`,
						title: "Add Job",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/Job-List`,
						title: "View Applicants",
						type: "link",
					},
				],
			},
			{
				title: "Company Data",
				icon: HardDrive,
				type: "sub",
				active: false,
				children: [
					{
						path: `Edit-Profile`,
						title: "Company Profile",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/ViewRecruitContact/:companyID`,
						title: "Users",
						type: "link",
					},
				],
			},
		],
	},
];
