import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Col, Card, Container } from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import ModalDeleteBadge from "../ModalDeleteBadge/ModalBadge";
import ModelNewsFeed from "../ModalDeleteNewsFeed/ModalNewsFeed";
import "./styles.css";
import ModalDeleteNewsFeed from "../ModalDeleteNewsFeed/ModalNewsFeed";
import ModalDeleteCandidateQuestionTemplate from "../ModalDeleteCandidateQuestionTemplate/ModalCandidateQuestionTemplate";
//states
const ViewCandidateQuestionTemplates = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/CandidateQuestionTemplate`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setData(res.data);
				setPageLoaded(true);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The must have was successfully added", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the job", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	const deleteHandler = async (id) => {
		const confirmed = window.confirm(`Are you sure you would like to delete`);
		try {
			if (confirmed) {
				window.location.reload(false);
				const response = await axios.delete(`${API_BASE_URL}/Candidate/${id}`);
			}
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	function actionColumnBodyTemplate(candidateTemplate) {
		return (
			<>
				<Link
					to={`/CandidateQuestionTemplate/EditCandidateQuestion/:${candidateTemplate.candidateQuestionTemplateId}`}
				>
					<i
						className="candidate-icons"
						title="Edit Candidate Question Template"
					>
						<FaRegEdit />
					</i>
				</Link>

				<ModalDeleteCandidateQuestionTemplate
					candidateQuestionTemplateId={
						candidateTemplate.candidateQuestionTemplateId
					}
				/>
			</>
		);
	}

	return (
		<Fragment>
			<Breadcrumbs
				parent="Master Data"
				title="Candidate Question Templates"
				mainTitle="Candidate Question Templates"
			/>
			<Container fluid={true}>
				<Col>
					<Card>
						<DataTable
							scrollable
							rowHover
							rowClassName={"mustHaveRow"}
							value={data}
							size={"small"}
							paginator
							rows={25}
							rowsPerPageOptions={[10, 25, 50, 100]}
							filterDisplay={"row"}
							id="badge-table"
						>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								header="Candidate Question"
								field="candidateQuestion"
							></Column>

							<Column
								className="candidate-icon-list"
								body={actionColumnBodyTemplate}
								header=""
							></Column>
						</DataTable>
						<Link to={`${process.env.PUBLIC_URL}/AddCandidateQuestionTemplate`}>
							<Btn attrBtn={{ color: "primary", id: "category-btn" }}>
								Add Question Template
							</Btn>
						</Link>
					</Card>
				</Col>
			</Container>
		</Fragment>
	);
};

export default ViewCandidateQuestionTemplates;
