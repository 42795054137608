import React, { useState } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	FormGroup,
	Input,
	Form,
} from "reactstrap";
import {
	FaPencilAlt,
	FaRegTrashAlt,
	FaFilePdf,
	FaUserAlt,
	FaStar,
	FaSave,
	FaEnvelope,
	FaRegEnvelope,
} from "react-icons/fa";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import { LI, Breadcrumbs, Btn, H3 } from "../../AbstractElements";
import "./styles.css";
//states
const ModalQuestionDelete = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const deleteHandler = async () => {
		try {
			window.location.reload(false);
			const response = await axios.delete(
				`${API_BASE_URL}/SkillsTestQuestion/${props.skillTestQuestionId}`
			);
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	return (
		<i className="question-edit" title="Email" onClick={toggle}>
			<FaRegTrashAlt id="quesion-icon" />
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader>Delete Question?</ModalHeader>
				<ModalBody>
					<br /> Are you sure you want to delete this question? <br /> <br />
				</ModalBody>
				<ModalFooter>
					<Button color="info" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={deleteHandler}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</i>
	);
};

export default ModalQuestionDelete;
