import Bulletin from "../Components/Bulletin/Bulletin";
import CandidateDetails from "../Components/CandidateDetails/Main";
import EditCandidate from "../Components/EditCandidate/Main";
import EditJobs from "../Components/EditJobs/Main/EditJobs";
import CandidateSkills from "../Components/CandidateSkills/CandidateSkills";
import EditCandidateProfile from "../Components/Application/Users/EditCandidateNew/EditCandidateNew";
import AddJob from "../Components/Application/Users/AddJobNew";
import EditCandidateUser from "../Components/Application/Users/EditCandidateNew";
import CandidateCards from "../Components/JobSearch/JobCardView";
import Users from "../Components/Users/Users";
import Skills from "../Components/Skills/Skills";
import MainCard from "../Components/JobSearch/JobDetail/MainCard";
import MustHaves from "../Components/MustHave/MustHave";
import EditMustHave from "../Components/EditMustHave/EditMustHave";
import AddCandidateNew from "../Components/Application/Users/AddCandidate";
import EditJobComponent from "../Components/Application/Users/EditJobNew";
import EditSkills from "../Components/EditSkills/EditSkills";
import AddNewJob from "../Components/Application/Users/AddJobNew/AddJobNew";
import EditJobNew from "../Components/Application/Users/EditJobNew/EditJobNew";
import ListView from "../Components/JobSearch/ListView";
import BookmarkCandidates from "../Components/BookmarkCandidates/BookmarkCandidate";
import JobApplicants from "../Components/JobApplicants/JobApplicants";
import JobList from "../Components/JobList/JobList";
import BulletinListCard from "../Components/BulletinView/ListView/ListCard";
import BulCardsClass from "../Components/JobSearch/JobCardView/BulletinCardsClass";
import CandDetails from "../Components/CandidateDetail";
import ViewTests from "../Components/ViewTests/ViewTests";
import AddQuestions from "../Components/AddTestQuestions/AddTestQuestions";
import CreateTest from "../Components/AddTest/AddTest";
import ViewQuestions from "../Components/ViewQuestions/ViewQuestions";
import EditQuestions from "../Components/EditTestQuestions/EditTestQuestions";
import Badges from "../Components/Badges/Badges";
import AddBadge from "../Components/AddBadge/AddBadge";
import EditBadge from "../Components/EditBadge/EditBadge";
import EditTestLogo from "../Components/EditTestLogo/EditTestLogo";
import ViewNewsCategory from "../Components/ViewNewsCategory/ViewNewsCategory";
import AddNewsCategory from "../Components/AddNewsCategory/AddNewsCategory";
import EditNewsCategory from "../Components/EditNewsCategory/EditNewsCategory";
import EditNewsProvider from "../Components/EditNewsProvider/EditNewsProvider";
import ViewNewsProvider from "../Components/ViewNewsProvider/ViewNewsProvider";
import ViewNewsFeed from "../Components/ViewNewsFeeds/ViewNewsFeeds";
import AddNewsProvider from "../Components/AddNewsProvider/AddNewsProvider";
import AddNewsFeeds from "../Components/AddNewsFeeds/AddNewsFeeds";
import EditNewsFeeds from "../Components/EditNewsFeeds/EditNewsFeeds";
import ViewNewsCategoryTag from "../Components/ViewNewsCategoryTag/ViewNewsCategoryTag";
import AddNewsCategoryTag from "../Components/AddNewsCategoryTag/AddNewsCategoryTag";
import EditNewsCategoryTag from "../Components/EditNewsCategoryTag/EditNewsCategoryTag";
import ViewCandidateQuestionTemplates from "../Components/ViewCandidateTemplates/ViewCandidateTemplates";
import EditCandidateTemplates from "../Components/EditCandidateTemplates/EditCandidateTemplates";
import AddCandidateQuestionTemplate from "../Components/AddCandidateQuestionTemplate/AddCandidateQuestionTemplate";
import EditProfile from "../Components/Application/Users/EditProfile/EditProfile";
import ViewRecruitContact from "../Components/ViewRecruitContact/ViewRecruitContact";
import EditRecruitContact from "../Components/EditRecruitContact/EditRecruitContact";
import AddRecruitContact from "../Components/AddRecruitContact/AddRecruitContact";
export const routes = [
	{
		path: `${process.env.PUBLIC_URL}/AddNewJob`,
		Component: <AddJob />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Bookmarked-Candidates`,
		Component: <BookmarkCandidates />,
	},
	{
		path: `candidatedetails/:candidateId`,
		Component: <CandDetails />,
	},
	{
		path: `editcandidate/:id`,
		Component: <EditCandidateUser />,
	},
	{
		path: `EditJob/:id`,
		Component: <EditJobs />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Bulletin`,
		Component: <Bulletin />,
	},
	{
		path: `Edit-Profile`,
		Component: <EditProfile />,
	},
	{
		path: `editjob/:id`,
		Component: <EditJobComponent />,
	},
	{
		path: `editskill/:id`,
		Component: <EditSkills />,
	},
	{
		path: `job/:jobId`,
		Component: <MainCard />,
	},
	{
		path: `editmusthave/:id`,
		Component: <EditMustHave />,
	},
	{
		path: `${process.env.PUBLIC_URL}/skills`,
		Component: <CandidateSkills />,
	},
	{
		path: `${process.env.PUBLIC_URL}/users`,
		Component: <Users />,
	},
	{
		path: `${process.env.PUBLIC_URL}/View-Candidates`,
		Component: <CandidateCards />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Add-Candidates`,
		Component: <AddCandidateNew />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Add-Jobs`,
		Component: <AddJob />,
	},
	{
		path: `${process.env.PUBLIC_URL}/View-Jobs`,
		Component: <ListView />,
	},
	{
		path: `${process.env.PUBLIC_URL}/MustHaves`,
		Component: <MustHaves />,
	},
	{
		path: `${process.env.PUBLIC_URL}/ViewJobApplicants/:id`,
		Component: <JobApplicants />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Job-List`,
		Component: <JobList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/View-Bulletin`,
		Component: <BulCardsClass />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Add-Test-Questions:id`,
		Component: <AddQuestions />,
	},
	{
		path: `${process.env.PUBLIC_URL}/View-Tests`,
		Component: <ViewTests />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Create-Test`,
		Component: <CreateTest />,
	},
	{
		path: `${process.env.PUBLIC_URL}/View-Test-Questions:id`,
		Component: <ViewQuestions />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Edit-Test-Questions/:testId/:questionId`,
		Component: <EditQuestions />,
	},
	{
		path: `${process.env.PUBLIC_URL}/View-Badges`,
		Component: <Badges />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Add-Badge`,
		Component: <AddBadge />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Edit-Badge/:badgeId`,
		Component: <EditBadge />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Edit-TestLogo/:skillTestId`,
		Component: <EditTestLogo />,
	},
	{
		path: `${process.env.PUBLIC_URL}/ViewNewsCategories`,
		Component: <ViewNewsCategory />,
	},
	{
		path: `${process.env.PUBLIC_URL}/AddNewsCategories`,
		Component: <AddNewsCategory />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Edit-NewsCategory/:newsCategoryId`,
		Component: <EditNewsCategory />,
	},
	{
		path: `${process.env.PUBLIC_URL}/ViewNewsProvider`,
		Component: <ViewNewsProvider />,
	},
	{
		path: `${process.env.PUBLIC_URL}/AddNewsProvider`,
		Component: <AddNewsProvider />,
	},
	{
		path: `${process.env.PUBLIC_URL}/EditNewsProvider/:newsProviderId`,
		Component: <EditNewsProvider />,
	},
	{
		path: `${process.env.PUBLIC_URL}/ViewNewsFeeds`,
		Component: <ViewNewsFeed />,
	},
	{
		path: `${process.env.PUBLIC_URL}/AddNewsFeeds`,
		Component: <AddNewsFeeds />,
	},
	{
		path: `${process.env.PUBLIC_URL}/EditNewsFeeds/:newsFeedId`,
		Component: <EditNewsFeeds />,
	},
	{
		path: `${process.env.PUBLIC_URL}/ViewNewsCategoryTag/`,
		Component: <ViewNewsCategoryTag />,
	},
	{
		path: `${process.env.PUBLIC_URL}/NewsCategoryTag/AddNewsCategoryTag/`,
		Component: <AddNewsCategoryTag />,
	},
	{
		path: `${process.env.PUBLIC_URL}/EditNewsCategoryTag/:newsCategoryTagId`,
		Component: <EditNewsCategoryTag />,
	},
	{
		path: `${process.env.PUBLIC_URL}/AddCandidateQuestionTemplate`,
		Component: <AddCandidateQuestionTemplate />,
	},
	{
		path: `${process.env.PUBLIC_URL}/ViewCandidateQuestionTemplates`,
		Component: <ViewCandidateQuestionTemplates />,
	},
	{
		path: `${process.env.PUBLIC_URL}/CandidateQuestionTemplate/EditCandidateQuestion/:candidateQuestionTemplateId`,
		Component: <EditCandidateTemplates />,
	},
	{
		path: `${process.env.PUBLIC_URL}/ViewRecruitContact/:companyID`,
		Component: <ViewRecruitContact />,
	},
	{
		path: `${process.env.PUBLIC_URL}/EditRecruitContact/:recruitContactId`,
		Component: <EditRecruitContact />,
	},
	{
		path: `${process.env.PUBLIC_URL}/AddRecruitContact`,
		Component: <AddRecruitContact />,
	},
];
