import React, { Fragment, useState, useEffect } from "react";
import { Btn, H4 } from "../../../../AbstractElements";
import axios from "axios";
import AsyncSelect from "react-select/async";
import {
	Row,
	Col,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
} from "reactstrap";
import SalaryType from "../../../TableData/salaryType";
import WorkplaceType from "../../../TableData/workplaceType";
import JobType from "../../../TableData/jobType";
import { TopCenter, directiontoaster } from "../../../../Constant";
import { toast } from "react-toastify";

const EditJobNew = () => {
	let url = window.location.href;
	let jobId = url.substring(url.lastIndexOf(":") + 1);
	const [_inputValue, setValue] = useState([]);
	const [mustHave, setMustHave] = useState([]);
	const [skills, setSkills] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
//states for storing data
	const [job, setJob] = useState({
		jobTitle: "",
		companyId: "",
		createBy: "",
		jobLocation: "",
		jobType: "",
		salaryType: "",
		workplaceType: "",
		salaryRange: "",
		jobSummary: "",
		mustHave: [],
		skills: [],
	});

	const {
		jobTitle,
		companyId,
		createBy,
		jobLocation,
		jobType,
		salaryType,
		workplaceType,
		salaryRange,
		jobSummary,
	} = job;

	const onInputChange = (e) => {
		setJob({ ...job, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		loadJob(jobId);
	}, [jobId]);

	const loadJob = async (jobId) => {
		let result = await axios.get(`${API_BASE_URL}/Job/${jobId}`);
		setJob(result.data);
	};

	const handleInputChange = (value) => {
		setValue(value);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			await axios.put(`${API_BASE_URL}/Job/${jobId}`, job);
			let requestBody = mustHave.map((e) => e.mustHaveId);
			let response = await axios.put(
				`${API_BASE_URL}/JobMustHave/${jobId}`,
				requestBody
			);
			let requestSkillBody = skills.map((e) => e.skillId);
			let responseSkills = await axios.put(
				`${API_BASE_URL}/JobSkill/${jobId}`,
				requestSkillBody
			);
			// return directiontoaster("directionssuccessToast");
			window.location.href = "/viho/View-Jobs";
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setMustHave(values);
	};

	const handleSkills = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSkills(values);
	};

	const getSavedJobMustHave = async (_inputValue) => {
		const res = await axios.get(`${API_BASE_URL}/JobMustHave/${jobId}`);
		return await res.data;
	};

	const getSavedJobSkills = async (_inputValue) => {
		const res = await axios.get(`${API_BASE_URL}/JobSkill/${jobId}`);
		return await res.data;
	};

	const promiseOptions = async (_inputValue) => {
		let savedJobMustHaveTask = getSavedJobMustHave(mustHave);
		const result = await axios.get(`${API_BASE_URL}/MustHave`);
		let savedJob = await savedJobMustHaveTask;
		let allSavedJobs = await result.data;
		setMustHave(savedJob);
		return allSavedJobs;
	};

	const promiseOptionsSkills = async (_inputValue) => {
		let jobSavedSkill = getSavedJobSkills(skills);
		const result = await axios.get(`${API_BASE_URL}/Skills`);
		let savedJob = await jobSavedSkill;
		let allSavedJobs = await result.data;
		setSkills(savedJob);
		return allSavedJobs;
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The job was successfully updated", {
					position: toast.POSITION.TOP_CENTER,
					color: "danger",
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error updating the job", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Row>
				<div className="edit-profile">
					<Form id="form-card" onSubmit={onSubmit}>
						<Col xl="6" id="card-form-1">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>
										Candidate Experience
									</H4>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="12">
											<FormGroup className="mb-3">
												<Label className="form-label">Job Title</Label>
												<Input
													type="text"
													name="jobTitle"
													required={true}
													className="form-control"
													value={jobTitle}
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Job Location</Label>
												<Input
													type="text"
													className="form-control"
													required={true}
													name="jobLocation"
													value={jobLocation}
													onChange={(e) => onInputChange(e)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Company ID</Label>
												<Input
													type="number"
													name="companyId"
													required={true}
													className="form-control"
													value={companyId}
													onChange={(e) => onInputChange(e)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Salary Type</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="salaryType"
													className="form-control"
													value={salaryType}
													required={true}
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{SalaryType.map((salType) => (
														<option key={salType}>{salType}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Salary Range</Label>
												<Input
													type="number"
													id="exampleSelect"
													name="salaryRange"
													className="form-control"
													required={true}
													value={salaryRange}
													onChange={(e) => onInputChange(e)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Job Type</Label>
												<Input
													type="select"
													className="form-control"
													required={true}
													name="jobType"
													value={jobType}
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{JobType.map((type) => (
														<option key={type}>{type}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Workplace Type</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="workplaceType"
													className="form-control"
													required={true}
													value={workplaceType}
													onChange={(e) => onInputChange(e)}
												>
													<option key=""></option>
													{WorkplaceType.map((type) => (
														<option key={type}>{type}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Assign To</Label>
												<Input
													type="text"
													className="form-control"
													required={true}
													value={"Not ready"}
													onChange={(e) => onInputChange(e)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Create By</Label>
												<Input
													type="text"
													className="form-control"
													name="createBy"
													required={true}
													value={createBy}
													onChange={(e) => onInputChange(e)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">
													Mandatory Requirements
												</Label>
												<AsyncSelect
													cacheOptions
													defaultOptions
													selectOptions
													getOptionLabel={(e) => e.name}
													getOptionValue={(e) => e.mustHaveId}
													value={mustHave}
													loadOptions={promiseOptions}
													onInputChange={handleInputChange}
													onChange={handleChange}
													isMulti={true}
													required={true}
													className="form-control"
													id="form-skill"
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">Skills</Label>
												<AsyncSelect
													cacheOptions
													defaultOptions
													selectOptions
													getOptionLabel={(e) => e.name}
													getOptionValue={(e) => e.skillId}
													value={skills}
													loadOptions={promiseOptionsSkills}
													onInputChange={handleInputChange}
													isMulti={true}
													required={true}
													onChange={handleSkills}
													className="form-control"
													id="form-skill"
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<div className="cand-btn">
											<Col md="12">
												<Btn attrBtn={{ color: "primary", type: "submit" }}>
													Update Job
												</Btn>
											</Col>
										</div>
									</Row>
								</CardBody>
							</Card>
						</Col>
						<Col xl="6" id="card-form-2">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>
										Job Description
									</H4>
								</CardHeader>
								<CardBody>
									<Col md="12">
										<Label className="form-label">Job Summary</Label>
										<textarea
											rows="10"
											type="text"
											id="form-notes"
											name="jobSummary"
											className="form-control"
											required={true}
											onChange={(e) => onInputChange(e)}
											value={jobSummary}
										/>
									</Col>
								</CardBody>
							</Card>
						</Col>
					</Form>
				</div>
			</Row>
		</Fragment>
	);
};
export default EditJobNew;
