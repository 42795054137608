import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";
import ranks from "../TableData/rank";

const AddBadge = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [badgeName, setBadgeName] = useState("");
	const [badgeDescription, setBadgeDescription] = useState("");
	const [rank, setRank] = useState();
	const [isActive, setIsActive] = useState(false);
	const [image, setImage] = useState(null);
	let badgeId;

	const postBadge = async (e) => {
		e.preventDefault();

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		try {
			const formData = new FormData();
			formData.append("image", image);

			let requestUrl = `${API_BASE_URL}/Badge/AddBadge?name=${badgeName}`;
			requestUrl = `${requestUrl}&description=${badgeDescription}`;
			requestUrl = `${requestUrl}&rank=${rank}`;
			requestUrl = `${requestUrl}&active=${isActive}`;

			const badgePost = await axios
				.post(requestUrl, formData, config)
				.then((res) => console.log(res.data));

			setTimeout(function () {
				window.location.reload();
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch (error) {
			return directiontoaster("directionsdangerToast");
		}
	};
//modal
	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Badge was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the badge", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Container fluid={true}>
			<Breadcrumbs
				parent="Master Data"
				title="Badge Management - Add Badge"
				mainTitle="Badge Management - Add Badge"
			/>
			<Fragment>
				<Card>
					<CardBody>
						<Form onSubmit={postBadge}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setBadgeName(e.target.value)}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Description
									</Label>
									<textarea
										rows={10}
										type="text"
										name="description"
										required={true}
										className="form-control"
										onChange={(e) => setBadgeDescription(e.target.value)}
										id="test-question"
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Rank
									</Label>
									<Input
										type="select"
										required={true}
										className="form-control"
										name="description"
										onChange={(e) => setRank(e.target.value)}
									>
										<option key=""></option>
										{ranks.map((rank) => (
											<option key={rank}>{rank}</option>
										))}
									</Input>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Image
									</Label>
									<Input
										type="file"
										required={true}
										className="form-control"
										name="description"
										onChange={(e) => setImage(e.target.files[0])}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={isActive}
										onChange={(e) => setIsActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "badge-btn",
											type: "submit",
										}}
									>
										Add
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/View-Badges`}>
										<Btn attrBtn={{ color: "primary", id: "badge-cancel" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Fragment>
		</Container>
	);
};

export default AddBadge;
