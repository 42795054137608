import React, { Fragment, useState, useRef, useEffect, Component } from "react";
import { Btn, H4 } from "../../../../AbstractElements";
import axios from "axios";
import AsyncSelect from "react-select/async";
import {
	Row,
	Col,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
} from "reactstrap";
import SalaryType from "../../../TableData/salaryType";
import WorkplaceType from "../../../TableData/workplaceType";
import JobType from "../../../TableData/jobType";
import { toast } from "react-toastify";
import "../styes.css";

const AddNewJob = () => {
	const [jobId, setJobId] = useState([]);
	const [jobtitle, setJobTitle] = useState("");
	const [workplacetype, setWorkPlaceType] = useState("");
	const [jobType, setJobType] = useState("");
	const [salaryType, setSalaryType] = useState("");
	const [companyid, setCompanyId] = useState("");
	const [joblocation, setJobLocation] = useState("");
	const [createby, setCreateBy] = useState("");
	const [summary, setSummary] = useState("");
	const [salaryRange, setSalaryRange] = useState("");
	const [mustHave, setMustHave] = useState([]);
	const [errorMsg, setErrorMsg] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [file, setFile] = useState("");
	const [_inputValue, setValue] = useState([]);

	const [skills, setSkills] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const companyID = sessionStorage.getItem("companyID");

	const [assignTo, setAssignTo] = useState("");
	const [recruitContactId, setRecruitContactId] = useState("");
	const [recruitContacts, setRecruitContacts] = useState([]);
	const roleId = sessionStorage.getItem("roleId");
	const recruitName = sessionStorage.getItem("recruitName");

	useEffect(() => {
		loadRecruitContacts();
	}, []);

	const loadRecruitContacts = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/Recruit/${companyID}`);
			const recruitContacts = response.data;
			setRecruitContacts(recruitContacts);
		} catch (error) {
			console.error("Error loading Recruit Contacts:", error);
			setRecruitContacts([]);
		}
	};

	let handleSubmit = async (e) => {
		e.preventDefault();
		try {
			let assignToValue = recruitContactId;
			if (roleId === "3") {
				assignToValue = sessionStorage.getItem("recruitContactId");
			}

			let res = await axios.post(
				`${API_BASE_URL}/Job/AddJob`,
				{
					jobId: jobId,
					jobTitle: jobtitle,
					workplaceType: workplacetype,
					companyId: companyID,
					jobLocation: joblocation,
					jobType: jobType,
					createBy: createby,
					salaryType: salaryType,
					jobSummary: summary,
					salaryRange: salaryRange,
					assignTo: assignToValue,
					mustHave: mustHave,
					skills: skills,
					recruitContactId: assignToValue,
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			if (!Array.isArray(mustHave)) {
				mustHave = [];
			}

			const newJob = await res.data;
			let requestBody = {
				jobId: newJob.jobId,
				mustHaveId: mustHave.map((e) => e.mustHaveId),
			};

			let requestSkills = {
				jobId: newJob.jobId,
				skillId: skills.map((e) => e.skillId),
			};

			let response = await fetch(`${API_BASE_URL}/JobMustHave/AddJobMustHave`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify(requestBody),
			});

			let responseSkills = await fetch(`${API_BASE_URL}/JobSkill/AddJobSkill`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify(requestSkills),
			});

			// let resJson = await res.data();
			//stores data in states
			if (res.status === 200) {
				setJobId([]);
				setJobTitle("");
				setWorkPlaceType("");
				setCompanyId("");
				setJobLocation("");
				setJobType("");
				setCreateBy("");
				setSummary("");
				setSalaryRange();
				setMustHave("");
				setAssignTo("");
				setSkills("");
			}

			setTimeout(function () {
				window.location.href = "/View-Jobs";
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch (err) {
			console.error(err);
		}
	};

	const handleInputChange = (value, metadata) => {
		const r = metadata;
		setValue(value);
	};

	// load options using API call
	const promiseOptions = async (_inputValue) => {
		const result = await axios.get(`${API_BASE_URL}/MustHave`);
		return result.data;
	};

	const promiseOptionsSkills = async (_inputValue) => {
		const result = await axios.get(`${API_BASE_URL}/Skills`);
		return result.data;
	};

	const handleMustHave = (items) => {
		setMustHave(items);
	};

	const handleSkills = (items) => {
		setSkills(items);
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The job was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the job", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Row>
				<div className="edit-profile">
					<Form id="form-card" onSubmit={handleSubmit}>
						<Col xl="6" id="card-form-1">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>
										Job Information
									</H4>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="12">
											<FormGroup className="mb-3">
												<Label className="form-label">Job Title</Label>
												<Input
													type="text"
													value={jobtitle}
													name="JobTitle"
													required={true}
													className="form-control"
													onChange={(e) => setJobTitle(e.target.value)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Job Location</Label>
												<Input
													type="text"
													className="form-control"
													required={true}
													name="JobLocation"
													value={joblocation}
													onChange={(e) => setJobLocation(e.target.value)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Salary Type</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="salaryType"
													value={salaryType}
													className="form-control"
													onChange={(e) => setSalaryType(e.target.value)}
													required={true}
												>
													<option key=""></option>
													{SalaryType.map((type) => (
														<option key={type}>{type}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Salary Range</Label>
												<Input
													type="number"
													id="exampleSelect"
													name="salaryRange"
													className="form-control"
													required={true}
													value={salaryRange}
													onChange={(e) => setSalaryRange(e.target.value)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Job Type</Label>
												<Input
													type="select"
													className="form-control"
													required={true}
													value={jobType}
													name="jobType"
													onChange={(e) => setJobType(e.target.value)}
												>
													<option key=""></option>
													{JobType.map((type) => (
														<option key={type}>{type}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Workplace Type</Label>
												<Input
													type="select"
													id="exampleSelect"
													name="WorkplaceType"
													value={workplacetype}
													className="form-control"
													onChange={(e) => setWorkPlaceType(e.target.value)}
													required={true}
												>
													<option key=""></option>
													{WorkplaceType.map((type) => (
														<option key={type}>{type}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										{roleId === "4" && (
											<Col sm="6" md="6">
												<FormGroup>
													<Label className="form-label">Assign To</Label>
													{roleId !== "3" ? (
														<select
															className="form-select"
															onChange={(e) =>
																setRecruitContactId(e.target.value)
															}
															value={recruitContactId}
														>
															<option value="">Select Recruit Contact</option>
															{recruitContacts.map((recruitContact) => (
																<option
																	key={recruitContact.recruitContactId}
																	value={recruitContact.recruitContactId}
																>
																	{recruitContact.name}
																</option>
															))}
														</select>
													) : (
														<Input
															type="text"
															className="form-control"
															disabled={true}
															value={recruitName}
														/>
													)}
												</FormGroup>
											</Col>
										)}

										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Create By</Label>
												<Input
													type="text"
													className="form-control"
													required={true}
													value={createby}
													onChange={(e) => setCreateBy(e.target.value)}
												></Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">
													Mandatory Requirements
												</Label>
												<AsyncSelect
													cacheOptions
													defaultOptions
													selectOptions
													getOptionLabel={(e) => e.name}
													getOptionValue={(e) => e.mustHaveId}
													value={mustHave}
													loadOptions={promiseOptions}
													onInputChange={handleInputChange}
													isMulti={true}
													required={true}
													onChange={handleMustHave}
													className="form-control"
													id="form-skill-mandatory"
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">Skills</Label>
												<AsyncSelect
													cacheOptions
													defaultOptions
													selectOptions
													getOptionLabel={(e) => e.name}
													getOptionValue={(e) => e.skillId}
													value={skills}
													loadOptions={promiseOptionsSkills}
													onInputChange={handleInputChange}
													isMulti={true}
													required={true}
													onChange={handleSkills}
													className="form-control"
													id="form-skill-skills"
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<div className="cand-btn">
											<Col md="12">
												<Btn attrBtn={{ color: "primary", type: "submit" }}>
													Add Job
												</Btn>
											</Col>
										</div>
									</Row>
								</CardBody>
							</Card>
						</Col>
						<Col xl="6" id="card-form-2">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>
										Job Description
									</H4>
								</CardHeader>
								<CardBody>
									<Col md="12">
										<Label className="form-label">Job Summary</Label>
										<textarea
											type="text"
											rows="10"
											className="form-control"
											required={true}
											value={summary}
											id="form-notes"
											name="summary"
											onChange={(e) => setSummary(e.target.value)}
										/>
									</Col>
								</CardBody>
							</Card>
						</Col>
					</Form>
				</div>
			</Row>
		</Fragment>
	);
};

export default AddNewJob;
