import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import {
	Row,
	Col,
	Card,
	Form,
	Label,
	Input,
	CardHeader,
	CardBody,
} from "reactstrap";
//states
const EditMustHave = (e) => {
	let url = window.location.href;
	let mustHaveId = url.substring(url.lastIndexOf(":") + 1);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const [mustHave, setMustHave] = useState({
		name: "",
		description: "",
	});

	const { name, description } = mustHave;

	const onInputChange = (e) => {
		setMustHave({ ...mustHave, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		loadMustHave(mustHaveId);
	}, [mustHaveId]);

	const loadMustHave = async (mustHaveId) => {
		const result = await axios.get(`${API_BASE_URL}/MustHave/${mustHaveId}`);
		setMustHave(result.data);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		await axios.put(`${API_BASE_URL}/MustHave/${mustHaveId}`, mustHave);
		window.location.href = "/MustHaves";
		return false;
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Jobs"
				title="Update Requirement"
				mainTitle="Update Requirement"
			/>
			<Card>
				<CardHeader className="pb-0"></CardHeader>
				<CardBody>
					<Form onSubmit={onSubmit}>
						<Row className="g-3">
							<Col md="6">
								<Label className="form-label" for="validationCustom01">
									Name
								</Label>
								<Input
									type="text"
									name="name"
									required={true}
									className="form-control"
									value={name}
									onChange={(e) => onInputChange(e)}
								/>
							</Col>
							<Col md="6">
								<Label className="form-label" for="validationCustom02">
									Description
								</Label>
								<Input
									type="text"
									required={true}
									className="form-control"
									name="description"
									value={description}
									onChange={(e) => onInputChange(e)}
								/>
							</Col>
							<Row className="g-3">
								<Col md="4">
									<Btn type="submit" attrBtn={{ color: "primary" }}>
										{"Update Requirement"}
									</Btn>
								</Col>
							</Row>
						</Row>
					</Form>
				</CardBody>
			</Card>
		</Fragment>
	);
};

export default EditMustHave;
