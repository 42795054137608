import { Breadcrumbs } from "../../AbstractElements";
import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import CandidateMainCard from "./MainCard/index";
import Sidebar from "../JobSearch/JobCardView/Sidebar";

const CandDetails = () => {
	return (
		<Fragment>
			<Breadcrumbs
				mainTitle="Candidate Details"
				parent="Candidates"
				title="Candidate Details"
			/>
			<Container fluid={true}>
				<Row>
					<CandidateMainCard />
				</Row>
			</Container>
		</Fragment>
	);
};

export default CandDetails;
