import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import "./styles.css";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";

import {
	Row,
	Col,
	Card,
	Form,
	Label,
	Input,
	CardHeader,
	CardBody,
	Table,
	Container,
} from "reactstrap";

const MustHaves = (e) => {
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	let handleSubmit = async (e) => {
		e.preventDefault();
		try {
			let res = await axios.post(
				`${API_BASE_URL}/MustHave/AddMustHave`,
				{
					name: name,
					description: description,
				},
				{ headers: { "Content-Type": "application/json" } }
			);
			if (res.status === 200) {
				setName("");
				setDescription("");
			}
			setTimeout(function () {
				window.location.href = "/MustHaves";
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch (err) {
			console.error(err);
		}
	};

	const [data, setDate] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });

	useEffect(() => {
		let isMounted = true;
		axios
			.get(`${API_BASE_URL}/MustHave`)
			.then((res) => {
				if (isMounted) {
					setDate(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const deleteHandler = async (id, item) => {
		const confirmed = window.confirm(
			`Are you sure you would like to delete ${item}`
		);
		try {
			if (confirmed) {
				const response = await axios.delete(`${API_BASE_URL}/MustHave/${id}`);
			}
		} catch (error) {
			const reponseText = error.response.data;
			setError({ error: true, message: reponseText });
			console.error("Something went wrong", error);
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The must have was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the job", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Master Data"
				title="Mandatory Requirements"
				mainTitle="Mandatory Requirements"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={handleSubmit}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Description
									</Label>
									<Input
										type="text"
										required={true}
										className="form-control"
										name="description"
										onChange={(e) => setDescription(e.target.value)}
									/>
								</Col>
								<Row className="g-3">
									<Col md="4">
										<Btn type="submit" attrBtn={{ color: "primary" }}>
											{"Add Requirement"}
										</Btn>
									</Col>
								</Row>
							</Row>
						</Form>
					</CardBody>
					<br></br>
					<Table id="mandatory-table">
						<thead>
							<tr className="mustHaveRow">
								<th className="mustHaveHeaders">Mandatory Requirement</th>
								<th className="mustHaveHeader-desc">Description</th>
								<th className="mustHaveHeaders-icons"></th>
							</tr>
						</thead>
						<tbody>
							{data.map(({ name, description, mustHaveId }) => {
								return (
									<tr key={name} className="mustHaveRow">
										<td className="row-item">{name}</td>
										<td className="row-item-desc">{description}</td>
										<td className="row-item-icons">
											<Link to={`/editmusthave/:${mustHaveId}`}>
												<i className="icon" title="Edit Candidate">
													<FaPencilAlt />
												</i>
											</Link>
											<i
												onClick={(e) => deleteHandler(mustHaveId, name)}
												title="Delete Must Have"
												className="icon"
												id="del-icon"
											>
												<FaRegTrashAlt />
											</i>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card>
			</Container>
		</Fragment>
	);
};

export default MustHaves;
