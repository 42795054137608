import React, { useState } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	FormGroup,
	Input,
	Form,
} from "reactstrap";
import {
	FaPencilAlt,
	FaRegTrashAlt,
	FaFilePdf,
	FaUserAlt,
	FaStar,
	FaSave,
	FaEnvelope,
	FaRegEnvelope,
} from "react-icons/fa";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";

const ModalDelete = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const bookmarkId = props.bookmarkId;

	const deleteHandler = async () => {
		try {
			await props.onDelete();
			toggle();
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	return (
		<i className="candidate-icons" title="Delete" onClick={toggle}>
			<FaRegTrashAlt />
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader>{props.deleteMessage}</ModalHeader>
				<ModalFooter>
					<Button id="cancel-mail" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={deleteHandler}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</i>
	);
};

export default ModalDelete;
