import React, { Fragment, useContext } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import {
	Row,
	Col,
	Card,
	Form,
	Label,
	Input,
	CardHeader,
	CardBody,
	Table,
} from "reactstrap";

const Users = () => {
	return (
		<Fragment>
			<Breadcrumbs parent="Master Data" title="Users" mainTitle="Users" />
			<Card>
				<CardHeader className="pb-0"></CardHeader>
				<CardBody></CardBody>
			</Card>
		</Fragment>
	);
};

export default Users;
