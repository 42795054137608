import React, { useState } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	FormGroup,
	Input,
	Form,
} from "reactstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";

const ModalDeleteBadge = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const badgeId = props.badgeId;

	const deleteHandler = async (id) => {
		try {
			window.location.reload(false);
			const response = await axios.delete(`${API_BASE_URL}/Badge/${badgeId}`);
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	return (
		<i className="candidate-icons" title="Email" onClick={toggle}>
			<FaRegTrashAlt />
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader>Are you sure you want to delete?</ModalHeader>
				<ModalFooter>
					<Button id="cancel-mail" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={deleteHandler}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</i>
	);
};

export default ModalDeleteBadge;
