import React, { Fragment, useState, useRef, useEffect, Component } from "react";
import { Btn, H4, Breadcrumbs, H5 } from "../../../../AbstractElements";
import axios from "axios";
import AsyncSelect from "react-select/async";
import salary from "../../../TableData/salary";
import genders from "../../../TableData/genders";
import rates from "../../../TableData/rates";
import equity from "../../../TableData/equity";
import experience from "../../../TableData/experience";
import { TopCenter, directiontoaster } from "../../../../Constant";
import { toast } from "react-toastify";
import Provinces from "../../../TableData/Provinces";

import {
	Row,
	Col,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
} from "reactstrap";

const AddNewCandidate = () => {
	const ref = useRef("");
	const [file, setFile] = useState("");
	const [_inputValue, setValue] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState();
	const [candidateId, setCandidateId] = useState([]);
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [cell, setCell] = useState("");
	const [email, setEmail] = useState("");
	const [idpassport, setIDPassport] = useState("");
	const [ee, setEE] = useState("");
	const [gender, setGender] = useState("");
	const [rating, setRating] = useState("");
	const [currentcompany, setCurrentCompany] = useState("");
	const [currentsalary, setCurrentSalary] = useState("");
	const [currentjobtitle, setCurrentJobTitle] = useState("");
	const [yearsexperience, setYearsExperience] = useState("");
	const [notes, setNotes] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [linkedIn, setLinkedIn] = useState("");
	const [message, setMessage] = useState("");
	const [Errors, setErrors] = useState("");
	const [errorMsg, setErrorMsg] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// if statement to validate uploaded document size
			const MAX_FILE_SIZE = 5120; // 1MB

			const fileSizeKiloBytes = file.size / 1024;

			if (fileSizeKiloBytes > MAX_FILE_SIZE) {
				setErrorMsg(`${file.name} size is greater than the limit`);
				alert(`${file.name} size is greater than the limit`);
				setIsSuccess(false);
				return;
			}

			setErrorMsg("");
			setIsSuccess(true);

			//if statement to validate if and ensure that the skillset is selected by the candidate
			if (!selectedSkills) {
				alert("Please choose a skill");
				setIsSuccess(false);
				return;
			} else {
				setIsSuccess(true);
			}

			let res = await fetch(`${API_BASE_URL}/Candidate/AddCandidate`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({
					name: name,
					surname: surname,
					cell: cell,
					email: email,
					idNumber: idpassport,
					ee: ee,
					gender: gender,
					rating: rating,
					currentcompany: currentcompany,
					currentsalary: currentsalary,
					currentjobtitle: currentjobtitle,
					yearsexperience: yearsexperience,
					notes: notes,
					city: city,
					province: province,
					file: file,
					// selectedSkills: selectedSkills,
					skillsets: [],
					linkedIn: linkedIn,
				}),
			});
//
			//skillset is put into an array when selected

			if (!Array.isArray(selectedSkills)) {
				selectedSkills = [];
			}
			const candidateId = await res.json();
			let requestBody = {
				candidateId: candidateId,
				skills: selectedSkills.map((ss) => ss.skillsetId),
			};

			const skillSetRequestBody = {
				candidateId: parseInt(candidateId),
				skills: selectedSkills.map((e) => e.skillsetId),
			};
			await axios.post(
				`${API_BASE_URL}/CandidateSkillset/AddCandidateSkillset`,
				skillSetRequestBody
			);

			const url = `${API_BASE_URL}/CandidateDocument/AddCandidateDocument/${candidateId}`;
			const formData = new FormData();
			formData.append("candidateId", candidateId);
			formData.append("File", file);
			formData.append("fileName", file.name);
			formData.append("Ref", ref);
			validateSelectedFile();

			const config = {
				method: "POST",
				headers: {
					"content-type": "multipart/form-data",
				},
				body: formData,
			};
			axios.post(url, formData, config);

			if (res.status === 200) {
				setCandidateId([]);
				setName("");
				setSurname("");
				setCell("");
				setEmail("");
				setIDPassport("");
				setEE("");
				setGender("");
				setRating("");
				setCurrentCompany("");
				setCurrentSalary("");
				setCurrentJobTitle("");
				setYearsExperience("");
				setNotes("");
				setValue("");
				setCity("");
				setProvince("");
				setSelectedSkills("");
				setLinkedIn("");
				window.location.href = "/View-Candidates";
			} else {
				setErrorMsg("Error candidate was not saved");
				alert(`Error ${name} not saved! ${e.message}`);
				validateSelectedFile();
			}

			let response = await fetch(
				`${API_BASE_URL}/CandidateSkillset/AddCandidateSkillset`,
				{
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify(requestBody),
				}
			);

			//Saving captured data into database
			if (res.status === 200) {
				setCandidateId([]);
				setName("");
				setSurname("");
				setCell("");
				setEmail("");
				setIDPassport("");
				setEE("");
				setGender("");
				setRating("");
				setCurrentCompany("");
				setCurrentSalary("");
				setCurrentJobTitle("");
				setYearsExperience("");
				setNotes("");
				setValue("");
				setSelectedSkills("");
				setCity("");
				setProvince("");
				setLinkedIn("");
			}

			res = await fetch(
				`${API_BASE_URL}/CandidateSkillset/AddCandidateSkillset`
			);

			//Saving captured data into database
			if (res.status === 200) {
				setCandidateId([]);
				setName("");
				setSurname("");
				setCell("");
				setEmail("");
				setIDPassport("");
				setEE("");
				setGender("");
				setRating("");
				setCurrentCompany("");
				setCurrentSalary("");
				setCurrentJobTitle("");
				setYearsExperience("");
				setNotes("");
				setValue("");
				setSelectedSkills("");
				setCity("");
				setProvince("");
				setLinkedIn("");
			}
			// return directiontoaster("directionssuccessToast");
			window.location.href = "/View-Candidates";
		} catch (err) {
			console.error(err);
		}
	};

	const handleChanges = (event) => {
		if (event.target.files.length > 0) {
			setFile(event.target.files && event.target.files[0]);
		}
	};

	const validateSelectedFile = () => {
		// const MIN_FILE_SIZE = 1024 // 1MB
		const MAX_FILE_SIZE = 5120; // 1MB

		if (!file) {
			alert("Please choose a file");
			setIsSuccess(false);
			return;
		}

		const fileSizeKiloBytes = file.size / 1024;

		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			setErrorMsg(`${file.name} size is greater than the limit`);
			alert(`${file.name} size is greater than the limit`);
			setIsSuccess(false);
			return;
		}

		setErrorMsg("");
		setIsSuccess(true);
	};

	//rest file input after document is selected
	const resetFileInput = (ref) => {
		validateSelectedFile();
		// 👇️ reset input value
		ref.current.value = null;
	};

	// handle input change event
	const handleInputChange = (value) => {
		setValue(value);
	};

	// handle selection
	const handleChange = (values) => {
		//handle array of selected files
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	//get animated components wrapper
	// load options using API call
	const promiseOptions = async (_inputValue) => {
		const result = await axios.get(`${API_BASE_URL}/Skillset`);
		return result.data;
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Candidate was successfully added", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the candidate", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Row>
				<div className="edit-profile">
					<Form id="form-card" onSubmit={handleSubmit}>
						<Col className="col-xl-4" xl="4" id="card-form-1">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>Profile</H4>
									<div className="card-options">
										<a className="card-options-collapse" href="#javascript">
											<i className="fe fe-chevron-up"></i>
										</a>
										<a className="card-options-remove" href="#javascript">
											<i className="fe fe-x"></i>
										</a>
									</div>
								</CardHeader>
								<CardBody>
									<FormGroup className="mb-3">
										<Label className="form-label">ID Number</Label>
										<Input
											className="form-control"
											type="text"
											name="password"
											id="examplePassword"
											// required
											value={idpassport}
											onChange={(e) => setIDPassport(e.target.value)}
										/>
									</FormGroup>
									<FormGroup className="mb-3">
										<Label className="form-label">First Name</Label>
										<Input
											className="form-control"
											type="text"
											name="nameDD"
											id="exampleEmail"
											required
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</FormGroup>
									<FormGroup className="mb-3">
										<Label className="form-label">Surname</Label>
										<Input
											className="form-control"
											type="text"
											name="surname"
											id="examplePassword"
											value={surname}
											required
											onChange={(e) => setSurname(e.target.value)}
										/>
									</FormGroup>
									<FormGroup className="mb-3">
										<Label className="form-label">Cellphone</Label>
										<Input
											className="form-control"
											type="text"
											required
											name="cell"
											value={cell}
											onChange={(e) => setCell(e.target.value)}
										/>
									</FormGroup>
									<FormGroup className="mb-3">
										<Label className="form-label">Email</Label>
										<Input
											className="form-control"
											type="text"
											name="email"
											id="exampleEmail"
											required
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</FormGroup>
									<FormGroup className="mb-3">
										<Label className="form-label">Gender</Label>
										<Input
											className="form-control"
											type="select"
											id="exampleSelect"
											name="gender"
											value={gender}
											onChange={(e) => setGender(e.target.value)}
											// required={true}
										>
											<option key=""></option>
											{genders.map((sex) => (
												<option key={sex}>{sex}</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup className="mb-3">
										<Label className="form-label">LinkedIn</Label>
										<Input
											className="form-control"
											type="text"
											required
											name="cell"
											value={linkedIn}
											onChange={(e) => setLinkedIn(e.target.value)}
										/>
									</FormGroup>
								</CardBody>
							</Card>
						</Col>
						<Col className="col-xl-8" xl="8" id="card-form-2">
							<Card>
								<CardHeader className="pb-0">
									<H4 attrH4={{ className: "card-title mb-0" }}>
										Candidate Experience
									</H4>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="6">
											<FormGroup className="mb-3">
												<Label className="form-label">Current Job Title</Label>
												<Input
													className="form-control"
													type="text"
													name="name"
													id="jobTitle"
													required
													value={currentjobtitle}
													onChange={(e) => setCurrentJobTitle(e.target.value)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Current Company</Label>
												<Input
													className="form-control"
													type="text"
													name="name"
													id="exampleEmail"
													required
													value={currentcompany}
													onChange={(e) => setCurrentCompany(e.target.value)}
												/>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup className="mb-3">
												<Label className="form-label">City</Label>
												<Input
													className="form-control"
													type="text"
													name="name"
													id="jobTitle"
													required={true}
													value={city}
													onChange={(e) => setCity(e.target.value)}
												/>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Province</Label>
												<Input
													className="form-control"
													type="select"
													name="province"
													id="exampleEmail"
													required={true}
													value={province}
													onChange={(e) => setProvince(e.target.value)}
												>
													<option key=""></option>
													{Provinces.map((province) => (
														<option key={province}>{province}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">Current Salary</Label>
												<Input
													className="form-control"
													type="select"
													id="exampleSelect"
													name="current salary"
													value={currentsalary}
													onChange={(e) => setCurrentSalary(e.target.value)}
													required={true}
												>
													{salary.map((wages) => (
														<option key={wages}>{wages}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="6">
											<FormGroup>
												<Label className="form-label">
													Years Of Experience
												</Label>
												<Input
													className="form-control"
													type="select"
													name="Years Experience"
													id="exampleSelect"
													value={yearsexperience}
													onChange={(e) => setYearsExperience(e.target.value)}
													required={true}
												>
													<option key=""></option>
													{experience.map((years) => (
														<option key={years}>{years}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">Employment Equity</Label>
												<Input
													className="form-control"
													type="select"
													id="exampleSelect"
													name="equity"
													value={ee}
													onChange={(e) => setEE(e.target.value)}
													// required={true}
												>
													<option key=""></option>
													{equity.map((employment) => (
														<option key={employment}>{employment}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">Skillset</Label>
												<AsyncSelect
													cacheOptions
													defaultOptions
													selectOptions
													getOptionLabel={(e) => e.name}
													getOptionValue={(e) => e.skillsetId}
													value={selectedSkills}
													loadOptions={promiseOptions}
													onInputChange={handleInputChange}
													onChange={handleChange}
													isMulti={true}
													required={true}
													className="form-control"
													id="form-skill"
												/>
											</FormGroup>
										</Col>
										<Col md="12">
											<Label className="form-label">Interview Notes</Label>
											<textarea
												className="form-control"
												rows="10"
												name="surname"
												id="form-notes"
												value={notes}
												// required
												onChange={(e) => setNotes(e.target.value)}
											/>
										</Col>
										<Col sm="6" md="12">
											<FormGroup>
												<Label className="form-label">Rating</Label>
												<Input
													className="form-control"
													type="select"
													name="select"
													id="exampleSelect"
													value={rating}
													onChange={(e) => setRating(e.target.value)}
													// required={true}
												>
													<option key=""></option>
													{rates.map((employment) => (
														<option key={employment}>{employment}</option>
													))}
												</Input>
											</FormGroup>
										</Col>
										<Col md="12">
											<div className="cv">
												<Label for="exampleSelect">
													Upload CV | Max CV size: 1 MB
												</Label>
												<input
													type="file"
													className="form-control"
													required={true}
													ref={ref}
													onChange={handleChanges}
													accept=".pdf, .doc, .docx"
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<div className="cand-btn">
											<Col md="12">
												<Btn attrBtn={{ color: "primary", type: "submit" }}>
													Add Candidate
												</Btn>
											</Col>
										</div>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Form>
				</div>
			</Row>
		</Fragment>
	);
};
export default AddNewCandidate;
