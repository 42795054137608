import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { useParams } from "react-router-dom";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

const EditNewsFeed = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);
	const { recruitContactId } = useParams();
	
	// const [active, setIsActive] = useState(null);
	const navigate = useNavigate();

	const [newsFeeds, setNewsFeed] = useState({
		name: "",
		companyID: sessionStorage.getItem("companyId") || 0,
		surname: "",
		email:"",
		applicationUserId:sessionStorage.getItem("applicationUserId") || 0,
		roleId: "",
	});

	const { name, companyID, surname, email,applicationUserId,roleId} = newsFeeds;
	const [roles, setRoles] = useState([]);
	

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Recruit/GetRecruitContact/${recruitContactId.slice(1)}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				// setIsActive(res.data.active);
				setNewsFeed(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Recruit/GetRecruitContact/${recruitContactId.slice(1)}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
				responseType: "arraybuffer",
			})
			.then((res) => {
				const base64 = btoa(
					new Uint8Array(res.data).reduce(
						(data, byte) => data + String.fromCharCode(byte),
						""
					)
				);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Account `, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setRoles(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Account`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				const accountData = res.data; // Rename the variable to accountData or something more descriptive
      setRoles(accountData);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	const onInputChange = (e) => {
		setNewsFeed({ ...newsFeeds, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		try {
			const requestUrl = `${API_BASE_URL}/Recruit/EditRecruitContact/${recruitContactId.slice(
				1
			)}`;
			const payload = {
				roleId: roleId,
				companyID: companyID,
				surname: surname,
				applicationUserId:applicationUserId,
				email:email,
				
				name: name,
			};
			const response = await axios.put(requestUrl, payload);
			console.log(response.data);
			setTimeout(function () {
				navigate(`${process.env.PUBLIC_URL}/ViewRecruitContact/:companyID`);
				window.location.reload();
			}, 2000);
			return directiontoaster("directionssuccessToast");
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Recruit Contact was successfully edited", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error editing the Recruit Contact", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="User Management"
				title="User Management"
				mainTitle="Edit User Management"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={onSubmit}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
									 Role:
									</Label>
									<select
                                  name="roleId"
                               value={roleId}
                    onChange={onInputChange}
                    className="form-control"
                  >
                    {roles.map((role) => (
                      <option key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </option>
                    ))}
                  </select>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name:
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										value={name}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Surname:
									</Label>
									<Input
										type="text"
										name="surname"
										required={true}
										className="form-control"
										value={surname}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Email:
									</Label>
									<Input
										type="text"
										name="email"
										required={true}
										className="form-control"
										value={email}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>

						

							
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Update
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewRecruitContact/:companyID`}>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default EditNewsFeed;
