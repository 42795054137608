import React, { useState } from "react";
import { FaRegEnvelope } from "react-icons/fa";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	FormGroup,
	Input,
} from "reactstrap";

const ModalLink = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const postMessage = async () => {
		setModal(false);

		try {
			let res = await axios.post(
				`${API_BASE_URL}/MailMessages/AddMailMessages`,
				{
					email: email,
					subject: subject,
					body: body,
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			setSubject("");
			setBody("");
			return directiontoaster("directionssuccessToast");
		} catch (err) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The mail was successfully sent", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error sending the mail", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	return (
		<i className="candidate-icons" title="Email" onClick={toggle}>
			<FaRegEnvelope />
			<Modal size="lg" isOpen={modal} toggle={toggle}>
				<ModalHeader>Mail Candidate</ModalHeader>
				<ModalBody style={{ height: "75vh" }}>
					<FormGroup>
						<Label for="exampleText">Subject</Label>
						<Input
							type="textarea"
							name="text"
							id="exampleText"
							onChange={(e) => setSubject(e.target.value)}
							value={subject}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="exampleText">Body</Label>
						<div className="App">
							<CKEditor
								editor={ClassicEditor}
								onChange={(event, editor) => {
									setBody(editor.getData());
								}}
								onBlur={(event, editor) => {}}
								onFocus={(event, editor) => {}}
							/>
						</div>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button id="cancel-mail" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={postMessage}>
						Send
					</Button>
				</ModalFooter>
			</Modal>
		</i>
	);
};

export default ModalLink;
