import { H6, UL, LI } from "../../../AbstractElements";
import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { Label } from "reactstrap";
import AsyncSelect from "react-select/async";
import { FaDownload } from "react-icons/fa";

//states
const CandidateDescription = () => {
	const [_inputValue, setValue] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [file, setFile] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const ref = useRef("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [candidateDocument, setCandidateDocument] = useState({
		documentName: null,
		hasDocument: false,
	});

	let url = window.location.href;
	let candidateId = url.substring(url.lastIndexOf(":") + 1);

	const [user, setUser] = useState({
		name: "",
		surname: "",
		cell: "",
		email: "",
		rating: "",
		gender: "",
		yearsExperience: "",
		currentSalary: "",
		ee: "",
		idNumber: "",
		currentCompany: "",
		selectedSkills: [],
		currentJobTitle: "",
		notes: "",
		city: "",
		province: "",
		linkedIn: "",
		createDate: "",
		updateDate: "",
	});

	const {
		name,
		surname,
		cell,
		email,
		rating,
		yearsExperience,
		currentSalary,
		gender,
		idNumber,
		ee,
		currentCompany,
		currentJobTitle,
		notes,
		city,
		province,
		linkedIn,
		createDate,
		updateDate,
	} = user;

	useEffect(() => {
		loadUser(candidateId);
		loadDocumentData(candidateId);
	}, [candidateId]);

	const loadDocumentData = (candidateId) => {
		axios
			.get(`${API_BASE_URL}/CandidateDocument/DocumentDetails/${candidateId}`)
			.then((response) => {
				let [documentName, hasDocument] = ["N/A", false];
				if (typeof response.data === "object") {
					documentName = response.data.documentName;
					hasDocument = true;
				}
				setCandidateDocument({ documentName, hasDocument });
			})
			.catch((error) => console.error({ error }));
	};

	const loadUser = async (candidateId) => {
		const result = await axios.get(`${API_BASE_URL}/Candidate/${candidateId}`);
		setUser(result.data);
	};

	const getSavedskills = async (_inputValue) => {
		const res = await axios.get(
			`${API_BASE_URL}/CandidateSkillset/${candidateId}`
		);
		return await res.data;
	};

	const promiseOptions = async (_inputValue) => {
		let savedSkillsTask = getSavedskills(selectedSkills);
		const res = await axios.get(`${API_BASE_URL}/Skillset`);
		let savedSkills = await savedSkillsTask;
		let allSkills = await res.data;
		setSelectedSkills(savedSkills);
		return allSkills;
	};

	const handleInputChange = (value) => {
		setValue(value);
	};

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	const downloadCandidateCV = async (e) => {
		e.preventDefault();
		await axios({
			url: `${API_BASE_URL}/CandidateDocument/${candidateId}`,
			method: "GET",
			responseType: "blob",
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.pdf");
			document.body.appendChild(link);
			link.click();
		});
	};

	const handleChanges = (event) => {
		if (event.target.files.length > 0) {
			setFile(event.target.files && event.target.files[0]);
		}
	};

	useEffect(() => {
		loadUser(candidateId);
	}, [candidateId]);

	return (
		<Fragment>
			<div className="job-description">
				<H6>Candidate Details</H6>
				<UL>
					<LI>
						Cell: <b>{cell}</b>
					</LI>
					<LI>
						Email: <b>{email}</b>
					</LI>
					<LI>
						City: <b>{city}</b>
					</LI>
					<LI>
						Province: <b>{province}</b>
					</LI>
					<LI>
						LinkedIn: <b>{linkedIn}</b>
					</LI>
					<LI>
						Years Of Experience: <b>{yearsExperience}</b>
					</LI>
					<LI>
						Rating: <b>{rating}</b>
					</LI>
					<LI>
						Current Salary: <b>{currentSalary}</b>
					</LI>
					<LI>
						Gender: <b>{gender}</b>
					</LI>
					<LI>
						ID Number: <b>{idNumber}</b>
					</LI>
					<LI>
						Employment Equity: <b>{ee}</b>
					</LI>
					<LI>
						Current Company: <b>{currentCompany}</b>
					</LI>
					<LI>
						Current Job Title: <b>{currentJobTitle}</b>
					</LI>
					<LI>
						Create Date: <b>{new Date(createDate).toDateString()}</b>
					</LI>
					<LI>
						Update Date: <b>{new Date(updateDate).toDateString()}</b>
					</LI>
				</UL>
				<div className="lower-section">
					<div className="cv-upload-1">
						<Label for="exampleSelect">
							Document Name: <b>{candidateDocument.documentName}</b>{" "}
							<i onClick={downloadCandidateCV} id="cv-icon">
								<FaDownload />
							</i>
						</Label>
					</div>
					<div className="interview-notes">
						<Label className="form-label" for="validationCustom01">
							Interview Notes
						</Label>
						<textarea
							rows={10}
							type="text"
							name="name"
							required={true}
							value={notes}
							className="form-control"
							id="test-question"
							readOnly
						/>
					</div>
					<Label className="form-label">
						Skillset <sup className="mandatory">*</sup>
					</Label>
					<AsyncSelect
						key={candidateId}
						cacheOptions
						defaultOptions
						selectOptions
						getOptionLabel={(e) => e.name}
						getOptionValue={(e) => e.skillsetId}
						value={selectedSkills}
						loadOptions={promiseOptions}
						onInputChange={handleInputChange}
						isMulti={true}
						className="form-control"
						id="form-skill"
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default CandidateDescription;
