import React, { useState, useEffect } from "react";
import { Container, Row, Col, TabContent, TabPane } from "reactstrap";
import LoginTab from "./Tabs/LoginTab";
import AuthTab from "./Tabs/AuthTab";
import "./Tabs/styles.css";
import axios from "axios";
import { H6, P, UL, LI, Btn, Image } from "../AbstractElements";
import { Link } from "react-router-dom";

const Logins = () => {
	const [selected, setSelected] = useState("firebase");

	const callbackNav = (select) => {
		setSelected(select);
	};

	const [data, setDate] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [rating, setRating] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	let url = window.location.href;
	let jobId = url.substring(url.lastIndexOf(":") + 1);

	useEffect(() => {
		sessionStorage.removeItem("token");
	}, []);

	return (
		<Container fluid={true} className="p-0">
			<Row>
				<Col xs="12">
					<div className="login-card">
						<div className="login-main login-tab" id="login-new-1">
							<TabContent activeTab={selected} className="content-login">
								<TabPane
									className="fade show"
									tabId={selected === "firebase" ? "firebase" : "jwt"}
								>
									<LoginTab selected={selected} />
								</TabPane>
								<TabPane className="fade show" tabId="auth0">
									<AuthTab />
								</TabPane>
							</TabContent>
						</div>
						<div className="login-main login-tab" id="login-new-2">
							<TabContent activeTab={selected} className="content-login">
								<div className="media" id="login-media">
									<div className="login-top">
										<Image
											attrImage={{
												className: "img-fluid login-icon",
												src: `/skilled-up-logo.svg`,
												alt: "",
											}}
										/>
									</div>
									<div className="media-body">
										<div className="detail"></div>
										<div className="login-text">
											<div>
												<br></br>
												<div>
													<b>Welcome back!</b>
												</div>
												<br></br>
												<div>
													Our space provides a platform for software developers
													to connect with other software developers and
													potentially find work opportunities.
												</div>
												<br></br>
												<div>
													We are working hard to continuously add new features
													and bring extra benefits to our users.
												</div>
												<br></br>
												<div>
													Please take the time to keep your information up to
													date.
												</div>
											</div>
										</div>
									</div>
								</div>
							</TabContent>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Logins;
