import React, { Fragment, useState } from "react";
import { Facebook, Linkedin, Twitter } from "react-feather";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, H5, H6, LI, UL, P, Image } from "../../../AbstractElements";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import { TopCenter, directiontoaster } from "../../../Constant";
import { toast, ToastContainer } from "react-toastify";

const RegisterFrom = () => {
	const [togglePassword, setTogglePassword] = useState(false);
	const [passwordConfirm, setConfirmPassword] = useState("");
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const[company,setCompany]=useState("");
	const [password, setPassword] = useState("");
	const history = useNavigate();
	const [loading, setLoading] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const checkHandler = () => {
		setIsChecked(!isChecked);
	};
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const logUserIn = async (email) => {
		const result = await axios.get(
			`${API_BASE_URL}/Company/GetByEmail?email=${email}`
		);
		sessionStorage.setItem("companyID", result.data.companyID);
		console.log(result.data.companyID);
		sessionStorage.setItem("name", result.data.name);
		sessionStorage.setItem("telephone", result.data.telephone);
		sessionStorage.setItem("email", result.data.email);
		sessionStorage.setItem("website", result.data.website);
		setTimeout(() => {
			history(`/Edit-Profile`);
		}, 200);
	};

	let handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (password === passwordConfirm && isChecked === true) {
			try {
				let res = await axios.post(
					`${API_BASE_URL}/Account/RecruitRegister`,
					{
						email: email,
						password: password,
					},
					{ headers: { "Content-Type": "application/json" } }
				);
				if (res.status === 200) {
					setEmail("");
					setPassword("");
					sessionStorage.setItem("email", email);
					if (res.data.success) {
						const applicationUserId = res.data.userId;
						const requestBody = {
							applicationUserId,
							companyID: -1,
							name: "",
							address: "",
						
							email,
							telephone: "",
							website: "",
							
						};
						await axios.post(
							`${API_BASE_URL}/Company/AddCompany`,
							requestBody
						);
						await logUserIn(email);
					}
				}
			} catch (err) {
				let errorMessge = "Error Occured During Registration.";
				errorMessge = err?.response?.data?.message ?? errorMessge;
				toast.error(errorMessge, { position: toast.POSITION.TOP_CENTER });
			} finally {
				setLoading(false);
			}
		} else {
			toast.error(
				isChecked === true
					? "Passwords Do Not Match"
					: "Please Agree with Privacy Policy",
				{ position: toast.POSITION.TOP_CENTER }
			);
		}
	};

	return (
		<Fragment>
			<div className="login-card">
				<Form
					className="theme-form login-form"
					id="reg-1"
					onSubmit={handleSubmit}
				>
					<ToastContainer />
					<H4>Create your account</H4>
					<H6>Enter your personal details to create account</H6>
					{/* <FormGroup>
						<Label>Company Name</Label>
						<div className="input-group">
							<span className="input-group-text">
								<i className="icon-email"></i>
							</span>
							<Input
								className="form-control"
								type="text"
								required={true}
								onChange={(e) => setCompany(e.target.value)}
								value={company}
							/>
						</div>
					</FormGroup> */}
					<FormGroup>
						<Label>Email Address</Label>
						<div className="input-group">
							<span className="input-group-text">
								<i className="icon-email"></i>
							</span>
							<Input
								className="form-control"
								type="text"
								required={true}
								onChange={(e) => setEmail(e.target.value)}
								value={email}
							/>
						</div>
					</FormGroup>
					<FormGroup className="position-relative">
						<Label>Password</Label>
						<div className="input-group">
							<span className="input-group-text">
								<i className="icon-lock"></i>
							</span>
							<Input
								type={togglePassword ? "text" : "password"}
								className="form-control"
								required={true}
								onChange={(e) => setPassword(e.target.value)}
								value={password}
							/>
							<div className="show-hide">
								<span
									className="show"
									onClick={() => setTogglePassword(!togglePassword)}
								></span>
							</div>
						</div>
					</FormGroup>
					<FormGroup className="position-relative">
						<Label>Confirm Password</Label>
						<div className="input-group">
							<span className="input-group-text">
								<i className="icon-lock"></i>
							</span>
							<Input
								type={togglePassword ? "text" : "password"}
								className="form-control"
								required={true}
								value={passwordConfirm}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
							<div className="show-hide">
								<span
									className="show"
									onClick={() => setTogglePassword(!togglePassword)}
								></span>
							</div>
						</div>
					</FormGroup>
					<FormGroup>
						<div className="checkbox">
							<input
								type="checkbox"
								id="checkbox"
								checked={isChecked}
								onChange={checkHandler}
							/>
							<Label htmlFor="checkbox" className="text-muted" id="reg-label">
								Agree with <span>Privacy Policy</span>
							</Label>
						</div>
					</FormGroup>
					<FormGroup>
						<Btn
							attrBtn={{
								className: "btn-block",
								color: "primary",
								type: "submit",
								disabled: loading ? loading : loading,
							}}
						>
							{loading ? "LOADING..." : "Create Account"}
						</Btn>
					</FormGroup>
					<p>
						Already have an account?
						<a className="ms-2" href={`${process.env.PUBLIC_URL}/login`}>
							Sign in
						</a>
					</p>
				</Form>

				<div className="theme-form login-form" id="reg-2">
					<div
						className="media"
						id="login-media"
						style={{ "text-align": "center" }}
					>
						<div className="login-top">
							<Image
								attrImage={{
									className: "img-fluid login-icon",
									src: `/skilled-up-logo.svg`,
									alt: "",
								}}
							/>
						</div>
						<div className="media-body">
							<div className="detail"></div>
							<div className="login-text">
								<P>
									<br></br>
									<p>
										<b>Thank you for visiting SkilledUp!</b>
									</p>
									<br></br>
									<p>
										We look forward to you becoming a part of our specialised
										community.
									</p>
									<br></br>
									<p>
										Our space provides a platform for software developers to
										connect with other software developers and potentially find
										work opportunities.
									</p>
									<br></br>
									<p>
										We are working hard to contiously add new features and bring
										extra benefits to our users.
									</p>
									<br></br>
									<p>Please take the time to register to enjoy the benefits.</p>
								</P>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RegisterFrom;
