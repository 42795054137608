import ListCard from "../../ListView/ListCard";
import { H6, P, UL, LI, Btn, Image } from "../../../../AbstractElements";
import JobDescription from "./jobDescription";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Card, Col, CardBody, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { Breadcrumbs } from "../../../../AbstractElements";
import "./style.css";
import { TopCenter, directiontoaster } from "../../../../Constant";
import { toast, ToastContainer } from "react-toastify";

const MainCard = () => {
	const [data, setDate] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [rating, setRating] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	let url = window.location.href;
	let jobId = url.substring(url.lastIndexOf(":") + 1);

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/Job/${jobId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				setDate(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	async function userJob() {
		const email = sessionStorage.getItem("email");
		const candidateId = sessionStorage.getItem("candidateId");
		const authToken = sessionStorage.getItem("token");

		try {
			let response = await axios({
				method: "post",
				url: `${API_BASE_URL}/CandidateJobApply/AddCandidateJobApply`,
				data: {
					candidateId: candidateId,
					jobId: jobId,
				},
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
			let message =
				"Thank you for your application. Your profile has been submitted for review";
			toast.success(message, { position: toast.POSITION.TOP_CENTER });
		} catch (err) {
			console.error(err);
		}
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle="View Job" parent="Jobs" title="View Job" />
			<Container fluid={true} id="jobCard">
				<div className="job-container">
					<Col xl="9" className="col-xl-12 xl-100 box-col-12" id="job-card">
						<Card id="job-card">
							<div className="job-search">
								<CardBody>
									<div className="media" id="job-detail-media">
										<Image
											attrImage={{
												className: "img-40 img-fluid m-r-20",
												src: `${require("../../../../assets/images/job-search/1.jpg")}`,
												alt: "",
											}}
										/>
										<div className="media-body">
											<H6 attrH6={{ className: "f-w-600" }}>
												<a href="#javascript">{data.jobTitle}</a>
											</H6>
											<P>
												{data.jobTitle}, {data.jobType} - {data.jobLocation} (
												{data.workplaceType}) | {data.salaryType} - {"R "}
												{data.salaryRange}
											</P>
											<Link to={`/EditJob/:${data.jobId}`}>
												<Btn
													attrBtn={{
														className:
															"btn btn-sm job-apply-btn job-apply-view",
														color: "primary",
													}}
												>
													Edit
												</Btn>
											</Link>
										</div>
									</div>
									<JobDescription />
								</CardBody>
							</div>
						</Card>
					</Col>
				</div>
			</Container>
		</Fragment>
	);
};

export default MainCard;
