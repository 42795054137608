import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import AddNewJob from "./AddJobNew";

//for fragments
const AddJob = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Add Job" parent="Jobs" title="Add Job" />
			<Container fluid={true}>
				<AddNewJob />
			</Container>
		</Fragment>
	);
};
export default AddJob;
