import { H6, P } from "../../../AbstractElements";
import JobDescription from "./jobDescription";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, Col, CardBody, Container } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import "./style.css";

//states
const CandidateMainCard = () => {
	const [_inputValue, setValue] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [file, setFile] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const ref = useRef("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const { candidateId } = useParams();

	const [user, setUser] = useState({
		name: "",
		surname: "",
		cell: "",
		email: "",
		rating: "",
		gender: "",
		yearsExperience: "",
		currentSalary: "",
		ee: "",
		idNumber: "",
		currentCompany: "",
		selectedSkills: [],
		currentJobTitle: "",
		notes: "",
	});

	const {
		name,
		surname,
		cell,
		email,
		rating,
		yearsExperience,
		currentSalary,
		gender,
		idNumber,
		ee,
		currentCompany,
		currentJobTitle,
		notes,
	} = user;

	useEffect(() => {
		loadUser(candidateId);
	}, [candidateId]);

	const loadUser = async (candidateId) => {
		const result = await axios.get(`${API_BASE_URL}/Candidate/${candidateId}`);
		setUser(result.data);
	};

	const loadUserPfp = async () => {
		const result = await axios.get(
			`${API_BASE_URL}/Candidate/GetAvatar/${candidateId}`
		);
	};

	const getSavedskills = async (_inputValue) => {
		const res = await axios.get(
			`${API_BASE_URL}/CandidateSkillset/${candidateId}`
		);
		return await res.data;
	};

	const promiseOptions = async (_inputValue) => {
		let savedSkillsTask = getSavedskills(selectedSkills);
		const res = await axios.get(`${API_BASE_URL}/Skillset`);
		let savedSkills = await savedSkillsTask;
		let allSkills = await res.data;
		setSelectedSkills(savedSkills);
		return allSkills;
	};

	useEffect(() => {
		loadUser(candidateId);
	}, [candidateId]);

	return (
		<Fragment>
			<Container fluid={true} id="jobCard">
				<div className="job-container">
					<Col xl="9" className="col-xl-12 xl-100 box-col-12" id="job-card">
						<Card id="job-card">
							<div className="job-search">
								<CardBody>
									<div className="media" id="job-detail-media">
										<img
											className="img-40 img-fluid m-r-20 candidate-pfp"
											src={`${API_BASE_URL}/Candidate/GetAvatar/${candidateId}`}
											alt=""
										/>
										<div className="media-body">
											<H6 attrH6={{ className: "f-w-600" }}>
												<a href="#javascript">
													{name} {surname}
												</a>
											</H6>
											<P>{currentJobTitle} </P>
										</div>
									</div>
									<JobDescription />
								</CardBody>
							</div>
						</Card>
					</Col>
				</div>
			</Container>
		</Fragment>
	);
};

export default CandidateMainCard;
