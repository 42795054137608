import React, { Fragment, useState } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import EditMyProfile from "./EditmyProfile";

const AddCandidateNew = () => {
	const [profile, setProfile] = useState({});
	const [editProfile, setEditProfile] = useState({});

	const onProfileUpdate = (updatedProfile) => {
		setProfile(updatedProfile);
	};

	const onEditUpdate = (editedProfile) => {
		setEditProfile(editedProfile);
	};

	return (
		<Fragment>
			<Breadcrumbs
				mainTitle="Add Candidates"
				parent="Candidates"
				title="Add Candidate"
			/>
			<Container fluid={true}>
				<EditMyProfile />
			</Container>
		</Fragment>
	);
};
export default AddCandidateNew;
