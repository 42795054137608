import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import "./styles.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/ext-language_tools";
import { toast } from "react-toastify";
import { TopCenter, directiontoaster } from "../../Constant";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
	Row,
	Col,
	Card,
	Form,
	Label,
	Input,
	CardHeader,
	CardBody,
	Table,
	Container,
	FormGroup,
} from "reactstrap";
//states
const EditQuestions = () => {
	const [testName, setTestName] = useState("");
	const [question, setQuestion] = useState("");
	const [questionCode, setQuestionCode] = useState("");
	const [answers, setAnswers] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	let count = 0;
	const { testId, questionId } = useParams();
	const [isSaving, setIsSaving] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/SkillsTestQuestion/${questionId.slice(1)}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				setQuestion(res.data.question);
				setQuestionCode(res.data.questionCode);
				setAnswers(res.data.answers);
				setTestName(res.data.question);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [questionId]);

	const postEditTest = async (e) => {
		e.preventDefault();
		setIsSaving(true);

		try {
			let res = await axios.put(
				`${API_BASE_URL}/SkillsTestQuestion/${questionId.slice(1)}`,
				{
					skillTestQuestionId: questionId.slice(1),
					skillTestId: testId.slice(1),
					question: question,
					questionCode: questionCode,
					answers: [...answers],
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			setTimeout(function () {
				navigate(`/View-Test-Questions:${testId.slice(1)}`);
			}, 2300);

			return directiontoaster("directionssuccessToast");
		} catch (err) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const updateQuestionCode = (newCode) => {
		setQuestionCode(newCode);
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Question edited successfully", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error editing the question", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Tests"
				title="Edit Question"
				mainTitle={`Edit Question : ${testName} `}
			/>
			<Container fluid={true}>
				<Form onSubmit={postEditTest}>
					<Card>
						<CardBody>
							<Row className="g-3">
								<Col md="12">
									<Label className="form-label" for="validationCustom01">
										Question
									</Label>
									<textarea
										rows={10}
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setQuestion(e.target.value)}
										id="test-question"
										value={question}
									/>
								</Col>
								<Col md="12">
									<Label className="form-label" for="validationCustom01">
										Code (optional)
									</Label>
									<AceEditor
										mode="javascript"
										onChange={(newCode) => updateQuestionCode(newCode)}
										value={questionCode}
										name="code-editor"
										theme="twilight"
										editorProps={{ $blockScrolling: true }}
										showPrintMargin={false}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							<input type="textarea" hidden></input>
							{answers.map((ans) => (
								<Col md="12" key={count++}>
									<Label className="form-label" for="validationCustom02">
										Answer
									</Label>
									<Input
										type="text"
										required={true}
										className="form-control"
										name="description"
										value={ans.answer}
										onChange={(e) => {
											ans.answer = e.target.value;
											setAnswers([...answers]);
										}}
									/>
									<div className="check-div">
										<input
											checked={ans.isCorrect}
											onChange={(event) => {
												ans.isCorrect = event.target.checked;
												setAnswers([...answers]);
											}}
											type="checkbox"
										/>
										<label htmlFor="louie" className="radio-label">
											Is correct?
										</label>
									</div>
								</Col>
							))}
							<br />
							<br />
							<Row className="g-3">
								<Col md="12">
									<Btn
										type="submit"
										attrBtn={{
											color: "primary",
											disabled: isSaving ? "disabled" : "",
										}}
									>
										{"Update Question"}
									</Btn>
									&nbsp; &nbsp;
									<Link
										to={`${process.env.PUBLIC_URL}/View-Test-Questions:${testId}`}
									>
										<Btn type="submit" attrBtn={{ color: "info" }}>
											{"Cancel"}
										</Btn>
									</Link>
								</Col>
							</Row>
						</CardBody>
						<br />
					</Card>
				</Form>
			</Container>
		</Fragment>
	);
};

export default EditQuestions;
