import React, { Fragment, useState, useEffect } from "react";
import { Btn, H4, Breadcrumbs } from "../../../AbstractElements";
import axios from "axios";
import AsyncSelect from "react-select/async";
import {
	Row,
	Col,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
	Container,
} from "reactstrap";
import SalaryType from "../../TableData/salaryType";
import WorkplaceType from "../../TableData/workplaceType";
import JobType from "../../TableData/jobType";
import { TopCenter, directiontoaster } from "../../../Constant";
import { toast } from "react-toastify";
import "./styes.css";
//states
const EditJobs = () => {
	const [errorMsg, setErrorMsg] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [file, setFile] = useState("");
	const [_inputValue, setValue] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [skills, setSkills] = useState([]);
	const [mustHave, setMustHave] = useState([]);
	const [recruitContactId, setRecruitContactId] = useState("");
	const [recruitContacts, setRecruitContacts] = useState([]);
	const companyID = sessionStorage.getItem("companyID");
	const [selectedAssign, setSelectedAssign] = useState("");
	const roleId = sessionStorage.getItem("roleId");
	const recruitName = sessionStorage.getItem("recruitName");
	useEffect(() => {
		loadRecruitContacts();
	}, []);

	const loadRecruitContacts = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/Recruit/${companyID}`);
			const recruitContacts = response.data;
			setRecruitContacts(recruitContacts);

			// Fetch the selected recruit contact based on recruitContactId
			const selectedRecruitContact = recruitContacts.find(
				(contact) => contact.recruitContactId === recruitContactId
			);

			// Set the selected recruit contact as the default option
			if (selectedRecruitContact) {
				setRecruitContactId(selectedRecruitContact.recruitContactId);
			}
		} catch (error) {
			console.error("Error loading Recruit Contacts:", error);
			setRecruitContacts([]);
		}
	};
	useEffect(() => {
		loadRecruitContacts();
	}, [recruitContactId]);

	let url = window.location.href;
	let jobId = url.substring(url.lastIndexOf(":") + 1);

	const [job, setJob] = useState({
		jobTitle: "",
		jobSummary: "",
		assignTo: "",
		salaryRange: 0,
		salaryType: "",
		companyId: 0,
		workplaceType: "",
		jobLocation: "",
		jobType: "",
		createBy: "",
		mustHave: [],
		skills: [],
	});

	const {
		jobTitle,
		jobSummary,
		assignTo,
		salaryRange,
		salaryType,
		companyId,
		workplaceType,
		jobLocation,
		jobType,
		createBy,
	} = job;

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			const updatedJob = {
				...job,
				assignTo: selectedAssign,
			};

			await axios.put(`${API_BASE_URL}/Job/${jobId}`, updatedJob);
			const mustHaveRequestBody = mustHave.map((e) => e.mustHaveId);
			const responseMustHave = await axios.put(
				`${API_BASE_URL}/JobMustHave/${jobId}`,
				mustHaveRequestBody
			);

			const skillRequestBody = skills.map((e) => e.skillId);
			const responseSkills = await axios.put(
				`${API_BASE_URL}/JobSkill/${jobId}`,
				skillRequestBody
			);

			directiontoaster("directionssuccessToast");
		} catch {
			directiontoaster("directionsdangerToast");
		}
	};

	const onInputChange = (e) => {
		setJob({ ...job, [e.target.name]: e.target.value });
	};

	const getSavedJobMustHave = async (_inputValue) => {
		const res = await axios.get(`${API_BASE_URL}/JobMustHave/${jobId}`);
		return await res.data;
	};

	const getSavedJobSkills = async (_inputValue) => {
		const res = await axios.get(`${API_BASE_URL}/JobSkill/${jobId}`);
		return await res.data;
	};

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/Job/${jobId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				setJob(res.data);
				setSelectedAssign(res.data.assignTo); // Set the initial value of selectedAssign
			})
			.catch((err) => {
				console.error(err);
			});
	}, [jobId]);

	const handleInputChange = (value) => {
		setValue(value);
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The job was successfully updated", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the job", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	const promiseOptions = async (_inputValue) => {
		let savedJobMustHaveTask = getSavedJobMustHave(mustHave);
		const result = await axios.get(`${API_BASE_URL}/MustHave`);
		let savedJob = await savedJobMustHaveTask;
		let allSavedJobs = await result.data;
		setMustHave(savedJob);
		return allSavedJobs;
	};

	const promiseOptionsSkills = async (_inputValue) => {
		let savedJobSkills = getSavedJobSkills(skills);
		const result = await axios.get(`${API_BASE_URL}/Skills`);
		let savedJob = await savedJobSkills;
		let allSavedJobs = await result.data;
		setSkills(savedJob);
		return allSavedJobs;
	};

	const handleChange = (values) => {
		//handle array of selected files
		values = Array.isArray(values) ? values : [...values];
		setMustHave(values);
	};

	const handleChangeSkills = (values) => {
		//handle array of selected files
		values = Array.isArray(values) ? values : [...values];
		setSkills(values);
	};

	const handleMustHave = (items) => {
		setMustHave(items);
	};

	return (
		<Fragment>
			<Breadcrumbs parent="Jobs" title="Edit Job" mainTitle="Edit Job" />
			<Container fluid={true}>
				<Row>
					<div className="edit-profile">
						<Form id="form-card" onSubmit={onSubmit}>
							<Col xl="6" id="card-form-1">
								<Card>
									<CardHeader className="pb-0">
										<H4 attrH4={{ className: "card-title mb-0" }}>
											Job Information
										</H4>
									</CardHeader>
									<CardBody>
										<Row>
											<Col md="12">
												<FormGroup className="mb-3">
													<Label className="form-label">Job Title</Label>
													<Input
														type="text"
														value={jobTitle}
														name="jobTitle"
														required={true}
														className="form-control"
														onChange={(e) => onInputChange(e)}
													/>
												</FormGroup>
											</Col>
											<Col sm="6" md="6">
												<FormGroup>
													<Label className="form-label">Job Location</Label>
													<Input
														type="text"
														className="form-control"
														required={true}
														name="jobLocation"
														value={jobLocation}
														onChange={(e) => onInputChange(e)}
													></Input>
												</FormGroup>
											</Col>

											<Col sm="6" md="6">
												<FormGroup>
													<Label className="form-label">Salary Type</Label>
													<Input
														type="select"
														className="form-control"
														required={true}
														value={salaryType}
														name="salaryType"
														onChange={(e) => onInputChange(e)}
													>
														<option key=""></option>
														{SalaryType.map((type) => (
															<option key={type}>{type}</option>
														))}
													</Input>
												</FormGroup>
											</Col>
											<Col sm="6" md="6">
												<FormGroup>
													<Label className="form-label">Salary Range</Label>
													<Input
														type="number"
														id="exampleSelect"
														name="salaryRange"
														className="form-control"
														required={true}
														value={salaryRange}
														onChange={(e) => onInputChange(e)}
													></Input>
												</FormGroup>
											</Col>
											<Col sm="6" md="6">
												<FormGroup>
													<Label className="form-label">Job Type</Label>
													<Input
														type="select"
														className="form-control"
														required={true}
														value={jobType}
														name="jobType"
														onChange={(e) => onInputChange(e)}
													>
														<option key=""></option>
														{JobType.map((type) => (
															<option key={type}>{type}</option>
														))}
													</Input>
												</FormGroup>
											</Col>
											<Col sm="6" md="6">
												<FormGroup>
													<Label className="form-label">Workplace Type</Label>
													<Input
														type="select"
														id="exampleSelect"
														name="workplaceType"
														value={workplaceType}
														className="form-control"
														required={true}
														onChange={(e) => onInputChange(e)}
													>
														<option key=""></option>
														{WorkplaceType.map((type) => (
															<option key={type}>{type}</option>
														))}
													</Input>
												</FormGroup>
											</Col>
											{roleId === "4" && (
												<Col sm="6" md="6">
													<FormGroup>
														<Label className="form-label">Assign To</Label>
														{roleId !== "3" ? (
															<select
																className="form-select"
																onChange={(e) =>
																	setSelectedAssign(e.target.value)
																}
																value={selectedAssign}
															>
																<option value="">Select Recruit Contact</option>
																{recruitContacts.map((recruitContact) => (
																	<option
																		key={recruitContact.recruitContactId}
																		value={recruitContact.recruitContactId}
																	>
																		{recruitContact.name}
																	</option>
																))}
															</select>
														) : (
															<Input
																type="text"
																className="form-control"
																value={recruitName}
															/>
														)}
													</FormGroup>
												</Col>
											)}

											<Col sm="6" md="6">
												<FormGroup>
													<Label className="form-label">Create By</Label>
													<Input
														type="text"
														className="form-control"
														required={true}
														value={createBy}
														name="createBy"
														onChange={(e) => onInputChange(e)}
													></Input>
												</FormGroup>
											</Col>
											<Col sm="6" md="12">
												<FormGroup>
													<Label className="form-label">
														Mandatory Requirements
													</Label>
													<AsyncSelect
														cacheOptions
														defaultOptions
														selectOptions
														getOptionLabel={(e) => e.name}
														getOptionValue={(e) => e.mustHaveId}
														value={mustHave}
														loadOptions={promiseOptions}
														onInputChange={handleInputChange}
														onChange={handleChange}
														isMulti={true}
														required={true}
														className="form-control"
														id="form-skill-mandatory"
													/>
												</FormGroup>
											</Col>
											<Col sm="6" md="12">
												<FormGroup>
													<Label className="form-label">Skills</Label>
													<AsyncSelect
														cacheOptions
														defaultOptions
														selectOptions
														getOptionLabel={(e) => e.name}
														getOptionValue={(e) => e.skillId}
														value={skills}
														loadOptions={promiseOptionsSkills}
														onInputChange={handleInputChange}
														onChange={handleChangeSkills}
														isMulti={true}
														required={true}
														className="form-control"
														id="form-skill-skills"
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<div className="cand-btn">
												<Col md="12">
													<Btn attrBtn={{ color: "primary", type: "submit" }}>
														Update Job
													</Btn>
												</Col>
											</div>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col xl="6" id="card-form-2">
								<Card>
									<CardHeader className="pb-0">
										<H4 attrH4={{ className: "card-title mb-0" }}>
											Job Description
										</H4>
									</CardHeader>
									<CardBody>
										<Col md="12">
											<Label className="form-label">Job Summary</Label>
											<textarea
												type="text"
												rows="10"
												className="form-control"
												required={true}
												value={jobSummary}
												id="form-notes"
												name="jobSummary"
												onChange={(e) => onInputChange(e)}
											/>
										</Col>
									</CardBody>
								</Card>
							</Col>
						</Form>
					</div>
				</Row>
			</Container>
		</Fragment>
	);
};
export default EditJobs;
