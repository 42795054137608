import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";
import ranks from "../TableData/rank";
import { useNavigate } from "react-router-dom";

const AddNewsProvider = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [name, setName] = useState("");
	const [url, setUrl] = useState("");
	const [countryId, setCountry] = useState("");
	const [active, setActive] = useState("");
	const [selectedNewsProvider, setSelectedNewsProvider] = useState([]);
	const [countries, setCountries] = useState([]);
	const navigate = useNavigate();

	const loadCountries = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/Country`);
			const countries = response.data;
			setCountries(countries);
		} catch (error) {
			console.error("Error loading countries:", error);
			setCountries([]);
		}
	};

	useEffect(() => {
		loadCountries();
	}, []);

	const postNewsProvider = async (e) => {
		e.preventDefault();

		const requestData = {
			Name: name,
			Url: url,
			CountryId: countryId,
			Active: active,
		};

		try {
			const response = await axios.post(
				`${API_BASE_URL}/NewsProvider/AddNewsProvider`,
				requestData
			);
			console.log(response.data);

			setTimeout(function () {
				navigate(`/ViewNewsProvider`);
			}, 2000);

			directiontoaster("directionssuccessToast");
		} catch (error) {
			directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Provider was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the Provider", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="News Feeds"
				title="Add Provider"
				mainTitle="Add Provider"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={postNewsProvider}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name:
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
							</Row>
							<Col md="4">
								<Label className="form-label" for="validationCustom01">
									Url:
								</Label>
								<Input
									type="text"
									name="url"
									required={true}
									className="form-control"
									onChange={(e) => setUrl(e.target.value)}
								/>
							</Col>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Country:
									</Label>
									<select
										className="form-select"
										onChange={(e) => setCountry(e.target.value)}
										value={countryId}
									>
										<option value="">Select Country</option>
										{countries.map((country) => (
											<option key={country.countryId} value={country.countryId}>
												{country.countryName}
											</option>
										))}
									</select>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={active}
										onChange={(e) => setActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Add
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewNewsProvider`}>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default AddNewsProvider;
