import React, { Fragment, useState } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import { toast } from "react-toastify";
import "./styles.css";

import {
	Row,
	Col,
	Card,
	Form,
	Label,
	Input,
	CardBody,
	Container,
} from "reactstrap";


//stattes
const Bulletin = () => {
	const [messageBody, setBody] = useState("");
	const [messageHeader, setHeader] = useState("");
	const userToken = sessionStorage.getItem("token");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const senderUserId = sessionStorage.getItem("userId");

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			let res = await axios.post(
				`${API_BASE_URL}/AddBulletinMessage`,
				{
					messageBody: messageBody,
					messageHeader: messageHeader,
					senderUserId: senderUserId,
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			if (res.status === 200) {
				setBody("");
				setHeader("");
			}
			return directiontoaster("directionssuccessToast");
		} catch (err) {
			console.error(err);
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The bulletin was successfully posted", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error posting the bulletin", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Bulletin"
				title="Post Bulletin"
				mainTitle="Post Bulletin"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={handleSubmit}>
							<Row className="g-3">
								<Row>
									<Col md="4" className="m-t-15">
										<Label className="form-label">Bulletin Header</Label>
										<Input
											type="text"
											name="messageHeader"
											required={true}
											className="form-control"
											onChange={(e) => setHeader(e.target.value)}
											value={messageHeader}
										/>
									</Col>
								</Row>
								<Row>
									<Col md="4" className="m-t-15">
										<Label className="form-label">Bulletin Body</Label>
										<textarea
											type="text"
											rows="10"
											value={messageBody}
											className="form-control"
											required={true}
											id="form-notes"
											name="messageBody"
											onChange={(e) => setBody(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="checkbox-container">
									<input
										type="checkbox"
										id="email"
										name="email"
										value="email"
										className="email-checkbox"
									></input>
									<label>Send email to all candidates</label>
								</div>
								<Row className="g-3">
									<Col md="4">
										<Btn type="submit" attrBtn={{ color: "primary" }}>
											{"Post Bulletin"}
										</Btn>
									</Col>
								</Row>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Bulletin;
