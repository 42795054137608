import React, { Fragment } from "react";
import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Btn, H4, H5 } from "../../../../AbstractElements";
import EditCandidateProfile from "./EditCandidateNew";

const EditCandidateUser = () => {
	return (
		<Fragment>
			<Breadcrumbs
				mainTitle="Edit Candidates"
				parent="Candidates"
				title="Edit Candidate"
			/>
			<Container fluid={true}>
				<EditCandidateProfile />
			</Container>
		</Fragment>
	);
};
export default EditCandidateUser;
