import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import { Card, Col, Table, Container } from "reactstrap";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import ModalDeleteBadge from "../ModalDeleteBadge/ModalBadge";
import "./styles.css";
import {
	FaPencilAlt,
	FaRegTrashAlt,
	FaRegFileCode,
	FaEye,
	FaPlus,
} from "react-icons/fa";

const ViewTests = () => {
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	function actionColumnBodyTemplate(SkillsTest) {
		return (
			<>
				{/* Edit */}
				<Link to={`/Edit-TestLogo/${SkillsTest.skillTestId}`}>
					<i className="candidate-icons" title="Edit Skills">
						<FaRegEdit />
					</i>
				</Link>
			</>
		);
	}

	const deleteHandler = async (id) => {
		const confirmed = window.confirm(`Are you sure you would like to delete`);
		try {
			if (confirmed) {
				window.location.reload(false);
				const response = await axios.delete(`${API_BASE_URL}/Candidate/${id}`);
			}
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	const downloadCandidateCV = (candidateId) => {
		fetch(`${API_BASE_URL}/CandidateDocument/${candidateId}`).then(
			(response) => {
				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = `${candidateId} .doc`;
					a.click();
				});
			}
		);
	};

	let bookmarkCandidate = async (candidateId) => {
		let userId = sessionStorage.getItem("token");

		try {
			let res = await axios.post(
				`${API_BASE_URL}/AddUserBookmark`,
				{
					bookmarkUserId: "5c6508cf-173e-4f66-8699-b137b2924352",
					candidateId: candidateId,
				},
				{ headers: { "Content-Type": "application/json" } }
			);
		} catch (err) {
			alert(err);
		}
	};

	useEffect(() => {
		let isMounted = true;

		axios
			.get(`${API_BASE_URL}/SkillsTest`)
			.then((res) => {
				if (isMounted) {
					setData(res.data);
					console.log(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const testName = (test) => {
		return (
			<>
				<Link to={`/View-Test-Questions:${test.skillTestId}`}>
					<div className="candidate-name">{test.name}</div>
				</Link>
			</>
		);
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="View Tests"
				title="View Tests"
				mainTitle="View Tests"
			/>
			<Container fluid={true}>
				<Col>
					<Card>
						<DataTable
							scrollable
							rowHover
							rowClassName={"mustHaveRow"}
							value={data}
							size={"small"}
							paginator
							rows={25}
							rowsPerPageOptions={[10, 25, 50, 100]}
							filterDisplay={"row"}
							id="badge-table"
						>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								header="Name"
								field="name"
								body={testName}
							></Column>
							<Column
								filterHeaderClassName="p-inputtext-sm"
								showFilterMenu={false}
								filter={true}
								field="description"
								header="Quiz Summary"
							></Column>

							<Column
								className="candidate-icon-list"
								body={actionColumnBodyTemplate}
								header=""
							></Column>
						</DataTable>
						<Link to={`${process.env.PUBLIC_URL}/Create-Test`}>
							<Btn attrBtn={{ color: "primary", id: "add-badge" }}>
								Add Test
							</Btn>
						</Link>
					</Card>
				</Col>
			</Container>
		</Fragment>
	);
};
export default ViewTests;
