import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import ModalLink from "../Modal/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { FaRegFilePdf, FaRegSave, FaRegEdit } from "react-icons/fa";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import "./styles.css";
import ranks from "../TableData/rank";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";

const AddNewsFeeds = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [active, setActive] = useState("");
	const [rssUrl, setRssUrl] = useState("");
	const [newsCategoryId, setCategoryId] = useState("");
	const [newsProviderId, setProviderId] = useState("");
	const [providers, setProviders] = useState([]);
	const [categories, setCategories] = useState([]);
	const [name, setName] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		loadProviders();
		loadCategories();
	}, []);

	const loadProviders = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/NewsProvider`);
			const providers = response.data;
			setProviders(providers);
		} catch (error) {
			console.error("Error loading providers:", error);
			setProviders([]);
		}
	};

	const loadCategories = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/GetAllNewsCategories`);
			const categories = response.data;
			setCategories(categories);
		} catch (error) {
			console.error("Error loading categories:", error);
			setCategories([]);
		}
	};

	const postNewsFeeds = async (e) => {
		e.preventDefault();

		const requestData = {
			Name: name,
			Active: active,
			RssUrl: rssUrl,
			NewsCategoryId: newsCategoryId,
			NewsProviderId: newsProviderId,
		};

		try {
			const response = await axios.post(
				`${API_BASE_URL}/NewsFeed/AddNewsFeed`,
				requestData
			);

			setTimeout(function () {
				navigate(`/ViewNewsFeeds`);
			}, 2000);

			directiontoaster("directionssuccessToast");
		} catch (error) {
			directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Feed was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the Feed", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="News Feeds"
				title="Feeds"
				mainTitle="Add News Feed"
			/>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Form onSubmit={postNewsFeeds}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Provider:
									</Label>
									<select
										className="form-select"
										onChange={(e) => setProviderId(e.target.value)}
										value={newsProviderId}
									>
										<option value="">Select Provider</option>
										{providers.map((provider) => (
											<option
												key={provider.newsProviderId}
												value={provider.newsProviderId}
											>
												{provider.name}
											</option>
										))}
									</select>
								</Col>
							</Row>
							<Col md="4">
								<Label className="form-label" for="validationCustom01">
									Category:
								</Label>
								<select
									className="form-select"
									onChange={(e) => setCategoryId(e.target.value)}
									value={newsCategoryId}
								>
									<option value="">Select Category</option>
									{categories.map((category) => (
										<option
											key={category.newsCategoryId}
											value={category.newsCategoryId}
										>
											{category.categoryName}
										</option>
									))}
								</select>
							</Col>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										RssUrl:
									</Label>
									<Input
										type="text"
										name="rssUrl"
										required={true}
										className="form-control"
										onChange={(e) => setRssUrl(e.target.value)}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name:
									</Label>
									<Input
										type="text"
										name="rssUrl"
										required={true}
										className="form-control"
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
							</Row>

							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={active}
										onChange={(e) => setActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "edit-btns",
											type: "submit",
										}}
									>
										Add
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/ViewNewsFeeds`}>
										<Btn attrBtn={{ color: "primary", id: "edit-btns" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default AddNewsFeeds;
