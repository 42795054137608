import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
	Col,
	Card,
	Container,
	CardBody,
	Label,
	Form,
	Row,
	Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ModalDelete from "../ModalDelete/Modal";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import { useParams } from "react-router-dom";
import "./styles.css";
import ranks from "../TableData/rank";
//states
const EditBadge = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [pageLoaded, setPageLoaded] = useState(false);
	const { badgeId } = useParams();
	const [isActive, setIsActive] = useState(null);
	const [image, setImage] = useState();
	const [imageData, setImageData] = useState(null);

	const [badge, setBadge] = useState({
		name: "",
		description: "",
		rank: "",
	});

	const { name, description, rank } = badge;

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Badge/${badgeId.slice(1)}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
			})
			.then((res) => {
				setIsActive(res.data.active);
				setBadge(res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const authToken = sessionStorage.getItem("token");

		axios
			.get(`${API_BASE_URL}/Badge/SearchBadge/${badgeId.slice(1)}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				cancelToken: source.token,
				responseType: "arraybuffer",
			})
			.then((res) => {
				const base64 = btoa(
					new Uint8Array(res.data).reduce(
						(data, byte) => data + String.fromCharCode(byte),
						""
					)
				);
				setImageData(`data:image/png;base64,${base64}`);
			})
			.catch((err) => {
				console.error(err);
			});

		return () => {
			source.cancel();
		};
	}, [API_BASE_URL]);

	const onInputChange = (e) => {
		setBadge({ ...badge, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		try {
			const formData = new FormData();
			formData.append("badgeId", badgeId.slice(1));
			if (image) {
				formData.append("image", image);
			}
			formData.append("name", name);
			formData.append("description", description);
			formData.append("rank", rank);
			formData.append("active", isActive);
			let requestUrl = `${API_BASE_URL}/Badge`;

			const badgePost = await axios
				.put(requestUrl, formData, config)
				.then((res) => console.log(res.data));

			setTimeout(function () {
				window.location.reload();
			}, 2000);

			return directiontoaster("directionssuccessToast");
		} catch {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Badge was successfully edited", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error editing the badge", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	return (
		<Container fluid={true}>
			<Breadcrumbs
				parent="Master Data"
				title="Badge Management - Edit Badge"
				mainTitle="Badge Management - Edit Badge"
			/>
			<Fragment>
				<Card>
					<CardBody>
						<Form onSubmit={onSubmit}>
							<Row className="g-3">
								<Col md="4">
									<Label className="form-label" for="validationCustom01">
										Name
									</Label>
									<Input
										type="text"
										name="name"
										required={true}
										className="form-control"
										value={name}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Description
									</Label>
									<textarea
										rows={10}
										type="text"
										name="description"
										required={true}
										className="form-control"
										value={description}
										onChange={(e) => onInputChange(e)}
										id="test-question"
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Rank
									</Label>
									<Input
										type="select"
										required={true}
										className="form-control"
										name="rank"
										value={rank}
										onChange={(e) => onInputChange(e)}
									>
										<option key=""></option>
										{ranks.map((rank) => (
											<option key={rank}>{rank}</option>
										))}
									</Input>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Image
									</Label>
									<Input
										type="file"
										className="form-control"
										name="description"
										onChange={(e) => setImage(e.target.files[0])}
										required={false}
									/>
								</Col>
								<Row>
									<img src={imageData} alt="" className="badge-image" />
								</Row>
							</Row>
							<Row>
								<Col md="4">
									<Label className="form-label" for="validationCustom02">
										Active
									</Label>
									<input
										type="checkbox"
										name="description"
										checked={isActive}
										onChange={(e) => setIsActive(e.target.checked)}
										className="is-active-check"
									/>
								</Col>
							</Row>
							<Row id="badge-row">
								<Col md="4">
									<Btn
										attrBtn={{
											color: "primary",
											id: "badge-btn",
											type: "submit",
										}}
									>
										Update
									</Btn>
									<Link to={`${process.env.PUBLIC_URL}/View-Badges`}>
										<Btn attrBtn={{ color: "primary", id: "badge-cancel" }}>
											Cancel
										</Btn>
									</Link>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Fragment>
		</Container>
	);
};

export default EditBadge;
