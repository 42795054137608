import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import ModalQuestionDelete from "../ModalQuestionDelete/ModalQuestionDelete";
import { FaRegEdit } from "react-icons/fa";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import "./styles.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ViewTests = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [data, setData] = useState({ questions: [] });
	let url = window.location.href;
	let testId = url.substring(url.lastIndexOf(":") + 1);

	useEffect(() => {
		let isMounted = true;
		const authToken = sessionStorage.getItem("token");

		const res = axios
			.get(`${API_BASE_URL}/SkillsTest/WIthQuestions/${testId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((response) => {
				if (isMounted) {
					setData(response.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		return () => {
			isMounted = false;
		};
	}, [API_BASE_URL]);

	const deleteHandler = async (id) => {
		const confirmed = window.confirm(`Are you sure you would like to delete`);
		try {
			if (confirmed) {
				window.location.reload(false);
				const response = await axios.delete(
					`${API_BASE_URL}/SkillsTestQuestion/${id}`
				);
			}
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	return (
		<Fragment>
			<Breadcrumbs
				parent="Tests"
				title="Questions"
				mainTitle={`Questions : ${data.name}`}
			/>
			<Container fluid={true}>
				<Row className="g-3">
					<Col md="12">
						<Link to={`/Add-Test-Questions:${testId}`}>
							<Btn attrBtn={{ color: "primary", id: "add-question-btn" }}>
								Add Skill Set Question{" "}
							</Btn>
						</Link>
						&nbsp; &nbsp;
						<Link to={`${process.env.PUBLIC_URL}/View-Tests`}>
							<Btn type="submit" attrBtn={{ color: "info" }}>
								{"Back To Tests"}
							</Btn>
						</Link>
						&nbsp; &nbsp;
						<Link to={`${process.env.PUBLIC_URL}/Edit-TestLogo/${testId}`}>
							<Btn type="submit" attrBtn={{ color: "info" }}>
								{"Update Tests Details"}
							</Btn>
						</Link>
					</Col>
				</Row>
				<br />
				<br />

				{data.questions.map((item, index) => {
					return (
						// Make this card draggable
						<Card key={index} style={{ padding: "20px" }} id="question-card">
							<div className="card-inner">
								<CardBody>
									<Row className="g-3">
										<Col md="12">
											<h3 className="test-question">
												{index + 1} : {item.question}
											</h3>
										</Col>
									</Row>
								</CardBody>
								<div className="question-actions">
									<Link
										to={`${process.env.PUBLIC_URL}/Edit-Test-Questions/:${testId}/:${item.skillTestQuestionId}`}
										id="question-edit"
									>
										<FaRegEdit id="edit-question-icon" />
									</Link>
									<ModalQuestionDelete
										skillTestQuestionId={item.skillTestQuestionId}
									/>
								</div>
							</div>
						</Card>
					);
				})}
			</Container>
		</Fragment>
	);
};

export default ViewTests;
